import { createTheme } from '@mui/material';

import * as themeEnums from './theme.enums';
import cssBaselineOverride from './cssBaselineOverride';

const { spaces, palette, radius, ...dimensions } = themeEnums;

const themeSettings = {
  colors: palette,
  spaces,
  radius,
  fonts: {
    plutoRegular: 'PlutoSansRegular, sans-serif',
    plutoLight: 'PlutoSansLight, sans-serif',
  },
  dimensions,
  components: { MuiCssBaseline: cssBaselineOverride },
};

const theme = createTheme(themeSettings);

export default theme;
