import styled from "@emotion/styled";
import { Button } from "@mui/material";

const FilledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.brandRed,
  textTransform: "none",
  fontSize: 18,
  fontWeight: 300,
  // padding: "10px 26px",
  margin: "1em 1em",
  height: "8em",
  width: "14em",
  borderRadius: theme.radius.base,
  lineHeight: "18px",
  color: theme.colors.brandWhite,
  "&:hover": {
    backgroundColor: theme.colors.brandRed,
  },
  "&.Mui-disabled": {
    opacity: 0.5,
    color: theme.colors.brandWhite,
  },
}));

export default FilledButton;
