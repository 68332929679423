import bboxPolygon from "@turf/bbox-polygon";
import bbox from "@turf/bbox";
import { featureCollection } from "@turf/helpers";

export const getDepotBounds = (boundaryData) => {
  const polygons = [];

  boundaryData.forEach((district) => {
    polygons.push(bboxPolygon(district.box_coordinates));
  });

  const unsortedBounds = bbox(featureCollection(polygons));

  return [unsortedBounds.slice(0, 2), unsortedBounds.slice(2, 4)];
};
