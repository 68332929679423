import { createSlice } from "@reduxjs/toolkit";

import { fetchUser, loginUser, logoutUser } from "./actions";

const initialState = {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(fetchUser.fulfilled, (state, action) => action.payload);
    builder.addCase(loginUser.fulfilled, (state, action) => action.payload);
    builder.addCase(logoutUser.pending, () => initialState);
  },
});

export default userSlice;
