import { groupBy } from "lodash";

export const groupByPostcodeLevel = (depotBoundaries) => {
  const districtBoundaries = [];
  const sectorBoundaries = [];
  depotBoundaries.map((boundaries) => {
    const boundariesByLevel = groupBy(boundaries, "type");
    districtBoundaries.push(boundariesByLevel["O"] || []);
    sectorBoundaries.push(boundariesByLevel["S"] || []);
  });

  return {
    districtBoundaries: districtBoundaries.flat(),
    sectorBoundaries: sectorBoundaries.flat(),
  };
};
