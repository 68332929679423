export const SUBMIT = 'Submit';
export const SUBMIT_FEEDBACK = 'Submit Feedback';

export const TOPIC_TYPES = {
  TOPIC: 'topic',
  SCHEDULE: 'schedule',
};

export const EMPTY_TOPIC_ID = 'EMPTY_TOPIC_ID';

export const WEEK_DAYS_MAP = [
  {
    label: 'Sunday',
    shortLabel: 'Sun',
  },
  {
    label: 'Monday',
    shortLabel: 'Mon',
  },
  {
    label: 'Tuesday',
    shortLabel: 'Tue',
  },
  {
    label: 'Wednesday',
    shortLabel: 'Wed',
  },
  {
    label: 'Thursday',
    shortLabel: 'Thur',
  },
  {
    label: 'Friday',
    shortLabel: 'Fri',
  },
  {
    label: 'Saturday',
    shortLabel: 'Sat',
  },
];

export const MAX_NOTIFICATION_COUNT = 99;
export const MAX_CHARS_COUNT = 1024;
export const MIN_CHARS_COUNT = 2;
export const PARCEL_NUMBER_LENGTH = 14;
export const PARCEL_NUMBER_REGEX = /^\d{10}$|^\d{14}$/; // 10 or 14 digits

export const FEEDBACK_TYPE = {
  LIKE: 1,
  DISLIKE: 2,
};

export const DATE_FORMATS = {
  GRAPH_AXIS_LABEL: 'MMM dd',
  GRAPH_BE: 'yyyy-MM-dd',
};

export const ACTION_BUTTON_TYPES = {
  DOCUMENT: 'pdf',
  MEDIA: 'media',
  FOLDER: 'folder',
  DASHBOARD: 'dashboard',
  LINK: 'link',
};

export const LLM_ROUTES = {
  TRACKING: 'tracking',
};
