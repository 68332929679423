import { createAsyncThunk } from "@reduxjs/toolkit";

import { createResponseInterceptor } from "../../../api/api";
import { SESSION_EXPIRED } from "../../../constants/strings";
import { UserActions } from "../user";
import { debouncedShowErrorSnackbar } from "../../../utils/snackbar";

export const onLaunchApp = createAsyncThunk(
  "app/launchApp",
  async (_, { dispatch }) => {
    await dispatch(UserActions.fetchUser());

    // create 401 error interceptor
    createResponseInterceptor(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          dispatch(UserActions.resetUser());
          debouncedShowErrorSnackbar(SESSION_EXPIRED);
        }

        return Promise.reject(error);
      }
    );
  }
);
