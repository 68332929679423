import { isCancel } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { actions } from "../app/slice";
import { SOMETHING_WENT_WRONG } from "../../../constants/strings";
import { showErrorSnackbar } from "../../../utils/snackbar";

export const createHandledAsyncThunk = (name, asyncFunc) =>
  createAsyncThunk(name, async (payload, options) => {
    try {
      return await asyncFunc(payload, options);
    } catch (e) {
      let errorMessage = e.message;

      if (e.response) {
        errorMessage = e.response.statusText;
        if (e.response.status === 500) {
          errorMessage = SOMETHING_WENT_WRONG;
        }
      }

      !isCancel(e) && showErrorSnackbar(errorMessage || SOMETHING_WENT_WRONG);
      throw e;
    }
  });

export const createLoadingAsyncThunk = (name, asyncFunc) =>
  createHandledAsyncThunk(name, async (payload, options) => {
    try {
      options.dispatch(actions.setLoading(true));
      return await asyncFunc(payload, options);
    } finally {
      options.dispatch(actions.setLoading(false));
    }
  });
