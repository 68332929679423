import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Marker } from "react-mapbox-gl";
import { DEPOT, OUTER, SECTOR } from "../../../../../constants/layers";
import { isMapAdjustingSelector } from "../../../../../reducers/map/selector";
import { setDepotsVisibleOnMapList } from "../../../../../reducers/app";
import {
  isMarkerVisible,
  setUpBoundariesWithDepotKeys,
  getCenterOfDepot,
  filterListByBusinessUnit,
} from "../../../../../utils/map";
import {
  allDepotListSelector,
  depotColourMapSelector,
  pageToShowIndexSelector,
} from "../../../../../reducers/app/selector";
import { businessUnitFilterSelector } from "../../../../../reducers/depot/selector";

const useStyles = makeStyles({
  marker: {
    fontSize: "12px",
    pointerEvents: "none",
  },
  depotMarker: {
    fontSize: "12px",
    pointerEvents: "none",
    padding: "0.1rem 0.3rem",
    border: "1px solid #FFF",
    color: "#000",
  },
});

export const LabelsLayer = ({
  mapLevel,
  districtBoundaries,
  sectorBoundaries,
  map,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const isMapAdjusting = useSelector(isMapAdjustingSelector);
  const allDepots = useSelector(allDepotListSelector);
  const depotColourMap = useSelector(depotColourMapSelector);
  const pageToShowIndex = useSelector(pageToShowIndexSelector);
  const businessUnitFilter = useSelector(businessUnitFilterSelector);

  const [labelMapBounds, setlabelMapBounds] = useState(map.getBounds()); // Need local state to stop rerender of whole map

  const depotBoundaries = setUpBoundariesWithDepotKeys(districtBoundaries);

  let boundariesList = [];
  if (mapLevel === SECTOR) {
    boundariesList = sectorBoundaries;
  } else if (mapLevel === OUTER || pageToShowIndex === 0) {
    // Stops rendering any depot level code if on Simulation page
    boundariesList = districtBoundaries;
  } else if (mapLevel === DEPOT) {
    boundariesList = filterListByBusinessUnit(allDepots, businessUnitFilter);
  }

  useEffect(() => {
    const checkDepotsVisibleOnMap = (mapBounds) => {
      const getDepots = districtBoundaries
        .filter(({ center, depotCode }) => {
          if (isMarkerVisible(center, mapBounds)) {
            return depotCode;
          }
          return false;
        })
        .map(({ depotCode }) => depotCode);

      const depotsOnMap = [...new Set(getDepots)];

      return allDepots.filter(({ depotCode }) =>
        depotsOnMap.includes(depotCode)
      );
    };

    setlabelMapBounds(map.getBounds());
    dispatch(
      setDepotsVisibleOnMapList(checkDepotsVisibleOnMap(map.getBounds()))
    );
  }, [
    isMapAdjusting,
    map,
    dispatch,
    businessUnitFilter,
    allDepots,
    districtBoundaries,
  ]); // Forces map to rerender on drag which updates the markers

  let value;
  let markerCenter;
  let depotColourIndex;

  return (
    <>
      {boundariesList
        .filter(({ center, depotName, depotCode }) => {
          if (depotName) {
            markerCenter = getCenterOfDepot(depotBoundaries[depotCode]);
          } else {
            markerCenter = center;
          }

          return isMarkerVisible(markerCenter, labelMapBounds);
        })
        .map(({ depotCode, depotName, center, postcode }) => {
          if (depotName) {
            value = depotCode;
            markerCenter = getCenterOfDepot(depotBoundaries[depotCode]);
            depotColourIndex = depotColourMap.findIndex(
              (depotMap) => depotMap.depot === depotCode
            );
          } else {
            value = postcode;
            markerCenter = center;
          }

          return (
            map && (
              <Marker
                className={`${depotName ? styles.depotMarker : styles.marker}`}
                style={{
                  backgroundColor: `${
                    depotName ? depotColourMap[depotColourIndex].mapColour : ""
                  }`,
                }}
                key={`${value} marker`}
                coordinates={markerCenter}
                anchor={"center"}
              >
                {value}
              </Marker>
            )
          );
        })}
    </>
  );
};
