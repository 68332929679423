import React from "react";
import { DepotBoundary } from "./Layers/DepotBoundary";
import { LabelsLayer } from "./Layers/LabelsLayer";
import { PopupLayer } from "./Layers/PopupLayer";
import { MultiDepotBoundary } from "./Layers/MultiDepotBoundary";
import { useSelector } from "react-redux";
import {
  defaultOverlayDataSelector,
  depotColourMapSelector,
  fullMapOverlayDateSelector,
  fullMapParcelListSelector,
  overlayTimeBandSelector,
  pageToShowIndexSelector,
  simulatedVolumeOverlayDataSelector,
} from "../../../../reducers/app/selector";
import {
  districtBoundariesForMutlipleDepotsSelector,
  districtBoundariesSelector,
  sectorBoundariesForMutlipleDepotsSelector,
  sectorBoundariesSelector,
} from "../../../../reducers/depot/selector";
import {
  setUpSimulationParcelList,
  setUpAllocationParcelList,
} from "../../../../utils/map";
import {
  mapLevelSelector,
  showMapParcelDataMarkersSelector,
} from "../../../../reducers/map/selector";
import { OUTER, SECTOR } from "../../../../constants/layers";

export const Layers = ({ map }) => {
  const dataList = useSelector(defaultOverlayDataSelector);
  const simulatedList = useSelector(simulatedVolumeOverlayDataSelector);
  const overlayTimeBand = useSelector(overlayTimeBandSelector);
  const mapLevel = useSelector(mapLevelSelector);
  const pageToShowIndex = useSelector(pageToShowIndexSelector);
  const depotColourMap = useSelector(depotColourMapSelector);
  const fullMapParcelList = useSelector(fullMapParcelListSelector);
  const fullMapTimeBand = useSelector(fullMapOverlayDateSelector);
  const showMapParcelDataMarkers = useSelector(
    showMapParcelDataMarkersSelector
  );

  const getSingleDistrictBoundaries = useSelector(districtBoundariesSelector);
  const getSingleSectorBoundaries = useSelector(sectorBoundariesSelector);

  const getMultipleDistrictBoundaries = useSelector(
    districtBoundariesForMutlipleDepotsSelector
  );
  const getMultipleSectorBoundaries = useSelector(
    sectorBoundariesForMutlipleDepotsSelector
  );

  let parcelMapData = [];

  let districtBoundaries;
  let sectorBoundaries;

  if (pageToShowIndex === 0) {
    //SIMULATION
    districtBoundaries = getSingleDistrictBoundaries;
    sectorBoundaries = getSingleSectorBoundaries;
  } else if (pageToShowIndex === 1) {
    districtBoundaries = getMultipleDistrictBoundaries;

    sectorBoundaries = getMultipleSectorBoundaries;
  }

  if (pageToShowIndex === 1) {
    parcelMapData = setUpAllocationParcelList(fullMapParcelList, mapLevel);
  } else if (mapLevel === SECTOR && pageToShowIndex === 0) {
    parcelMapData = setUpSimulationParcelList(
      SECTOR,
      dataList,
      simulatedList,
      overlayTimeBand,
      "list"
    );
  } else if (mapLevel === OUTER || pageToShowIndex === 0) {
    parcelMapData = setUpSimulationParcelList(
      OUTER,
      dataList,
      simulatedList,
      overlayTimeBand,
      "list"
    );
  }

  // Although the LabelsLayer and PopupLayer are setup the same way, they need to be rendered seperately to keep consistancy with overlapping on the map
  return (
    <>
      <DepotBoundary
        map={map}
        parcelMapData={parcelMapData}
        mapLevel={mapLevel}
        districtBoundaries={districtBoundaries}
        sectorBoundaries={sectorBoundaries}
        depotColourMap={depotColourMap}
        pageToShowIndex={pageToShowIndex}
      />

      {pageToShowIndex === 1 && mapLevel === OUTER && !fullMapTimeBand && (
        <MultiDepotBoundary
          map={map}
          mapLevel={mapLevel}
          districtBoundaries={districtBoundaries}
          sectorBoundaries={sectorBoundaries}
          depotColourMap={depotColourMap}
        />
      )}

      <LabelsLayer
        map={map}
        mapLevel={mapLevel}
        districtBoundaries={districtBoundaries}
        sectorBoundaries={sectorBoundaries}
      />

      {(pageToShowIndex === 0 || showMapParcelDataMarkers) && (
        <PopupLayer
          map={map}
          parcelMapData={parcelMapData}
          mapLevel={mapLevel}
          districtBoundaries={districtBoundaries}
          sectorBoundaries={sectorBoundaries}
        />
      )}
    </>
  );
};
