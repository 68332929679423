export const getFormattedMessages = (messages) =>
  messages.reduce((acc, { answer, question, ...rest }) => {
    const newMessages = [];

    if (answer) {
      newMessages.push({
        ...rest,
        message: answer,
        isUser: false,
      });
    }

    if (question) {
      newMessages.push({
        ...rest,
        id: rest.id + "#",
        message: question,
        isUser: true,
      });
    }

    return [...newMessages, ...acc];
  }, []);
