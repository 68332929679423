import { groupBy } from "lodash";
import { FIRST_TIMEBAND, DEFAULT_TIMERANGE } from "../../constants/timeBands";

export const checkClosestTimeBand = (
  overlayTimeBand,
  defaultOverlayDataList
) => {
  if (
    !defaultOverlayDataList.some((overlayData) => {
      return overlayData.timeBand === overlayTimeBand;
    })
  ) {
    // If selected timeBand isn't in the overlay data then select the closest timeBand (rounded down)
    const getClosestTimeBand = defaultOverlayDataList.reduce(
      (previousTimeBand, currentTimeBand) =>
        previousTimeBand.timeBand < overlayTimeBand &&
        currentTimeBand.timeBand > overlayTimeBand
          ? previousTimeBand
          : currentTimeBand
    );
    return getClosestTimeBand.timeBand;
  } else {
    return overlayTimeBand;
  }
};

export const checkFirstTimeBand = (
  firstBand = FIRST_TIMEBAND,
  defaultOverlayData = [[]],
  volumeDate
) => {
  const checkFirstBand = defaultOverlayData.some(
    (overlay) => overlay.timeBand === firstBand
  );

  if (!checkFirstBand && defaultOverlayData[0]?.dateProcessed) {
    const splitOverlayDataByDay = groupBy(defaultOverlayData, "dateProcessed");

    if (splitOverlayDataByDay[volumeDate]) {
      firstBand = splitOverlayDataByDay[volumeDate][0]?.timeBand.slice(0, 5);
    }
  }

  return firstBand;
};

export const setUpTimeBands = (
  firstBand = FIRST_TIMEBAND,
  selectedTimeRange = DEFAULT_TIMERANGE
) => {
  const timeBands = [firstBand];

  let currentBand = firstBand;
  for (let i = 0; i < selectedTimeRange * 4; i++) {
    const minutes = currentBand.slice(-2);
    const hour = currentBand.slice(0, 2);
    if (minutes !== "45") {
      currentBand =
        currentBand.slice(0, -2) + (parseInt(minutes) + 15).toString();
    } else if (hour === "23") {
      currentBand = "00:00";
    } else if (parseInt(hour) >= 9) {
      currentBand = `${parseInt(hour) + 1}:00`;
    } else {
      currentBand = `0${parseInt(hour) + 1}:00`;
    }

    timeBands.push(currentBand);
  }

  return timeBands;
};
