import styled from "@emotion/styled";

const PageContainer = styled.div(({ theme }) => ({
  height: "100%",
  width: "100%",
  overflow: "auto",
  backgroundColor: theme.colors.brandLightGrey,
  padding: `${theme.dimensions.home.paddingVertical}px ${theme.dimensions.home.paddingHorizontal}px`,
  [theme.breakpoints.down("lg")]: {
    padding: `${theme.dimensions.home.paddingVertical}px ${
      theme.dimensions.home.paddingHorizontal / 2
    }px`,
  },
  [theme.breakpoints.between(930, 1040)]: {
    padding: `${theme.dimensions.home.paddingVertical}px ${theme.dimensions.home.paddingHorizontal}px`,
  },
  [theme.breakpoints.down("sm")]: {
    padding: `${theme.dimensions.home.paddingVertical}px 0px`,
  },
}));

export default PageContainer;
