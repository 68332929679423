export const POSTCODE_DISTRICT_BOUNDARIES = {
  type: "vector",
  url: "mapbox://mapbox.boundaries-pos2-v3",
};

export const POSTCODE_SECTOR_BOUNDARIES = {
  type: "vector",
  url: "mapbox://mapbox.boundaries-pos3-v3",
};

export const POSTCODE_DISTRICT_BOUNDARIES_ID = "postcodeDistrictBoundaries";
export const POSTCODE_SECTOR_BOUNDARIES_ID = "postcodeSectorBoundaries";

export const BOUNDARIES_ID_BY_LEVEL = {
  DEPOT: POSTCODE_DISTRICT_BOUNDARIES_ID,
  OUTER: POSTCODE_DISTRICT_BOUNDARIES_ID,
  SECTOR: POSTCODE_SECTOR_BOUNDARIES_ID,
};
