import ReactPlayer from 'react-player';
import styled from '@emotion/styled';
import { YoutubeEmbed } from './YoutubeEmbed.jsx';

const Container = styled('div')({
  height: 160,
  flexShrink: 0,
});

const Image = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

const InfoMedia = ({ data }) => (
  <Container>
    {data.type === 'image' ? (
      <Image src={data.url} />
    ) : data.type === 'youtubeEmbed' ? (
      <YoutubeEmbed embedId={data.url} />
    ) : (
      <ReactPlayer url={data.url} width='100%' height='100%' controls={true} />
    )}
  </Container>
);

export default InfoMedia;
