import styled from '@emotion/styled';

const ActionContainer = styled('div')({
  display: 'flex',
  gap: 8,
});

const HeaderActions = ({ children, className }) => (
  <ActionContainer className={className}>{children}</ActionContainer>
);

export default HeaderActions;
