import React, { memo } from "react";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  box: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1rem",
  },
  icon: {
    height: "1.5rem",
  },
  label: {
    marginTop: "0.125rem",
    marginLeft: "0.3rem",
    height: "1rem",
    width: "auto",
  },
});

export const QuantumIcon = memo(() => {
  const styles = useStyles();

  return (
    <Box className={styles.box}>
      <SvgIcon viewBox="0 0 21 24" className={styles.icon}>
        <path
          d="M19.8602 5.19632L19.5784 5.37503C17.5339 6.57575 15.495 7.78206 13.4451 8.9772C13.2017 9.08792 13.0582 9.34618 13.0915 9.61386C13.1301 10.3287 13.0915 10.2394 13.6772 10.608C13.885 10.7721 14.1748 10.779 14.3899 10.6247C16.2686 9.50775 18.1583 8.3908 20.037 7.27385L20.3796 7.07838C20.3837 7.15651 20.3837 7.23481 20.3796 7.31294V17.5386C20.3869 17.8856 20.1923 18.2047 19.8823 18.354L13.528 22.1572C12.6052 22.7157 11.6769 23.2742 10.7652 23.8327C10.442 24.0558 10.0165 24.0558 9.69329 23.8327C6.65427 22.0009 3.61526 20.184 0.576247 18.3819C0.219003 18.1989 0.00230935 17.82 0.0236973 17.4158C0.0236973 14.0649 0.0236973 10.7308 0.0236973 7.41347C0.0236973 7.31294 0.0236973 7.218 0.0236973 7.07838L0.327599 7.24592L8.97498 12.3281C9.17192 12.4147 9.29048 12.6204 9.26783 12.8363C9.26783 15.3159 9.26783 17.79 9.26783 20.2696V20.5209C9.26064 20.6865 9.35199 20.8403 9.4999 20.9118L9.96404 21.1743C10.1101 21.2637 10.2932 21.2637 10.4392 21.1743L10.8813 20.9286C11.0405 20.8596 11.1384 20.6955 11.1244 20.5209C11.1244 20.4539 11.1244 20.3869 11.1244 20.3199V11.898C11.1244 11.8198 11.1244 11.7472 11.1244 11.6691C11.1297 11.5158 11.0482 11.3728 10.9144 11.3005L10.2127 10.8872L0.863571 5.37503L0.576247 5.2019L0.808318 5.03994L9.64909 0.158859C9.98765 -0.052953 10.4156 -0.052953 10.7542 0.158859C13.7103 1.80078 16.6554 3.41478 19.6005 5.03994L19.8602 5.19632Z"
          fill="white"
        />
      </SvgIcon>
      <SvgIcon viewBox="0 0 95 16" className={styles.label}>
        <path
          d="M6.85458 0.279993C10.3925 0.279993 13.0327 2.92827 13.0327 6.47696C13.0327 8.5928 12.0606 10.3938 10.508 11.4715L9.2989 10.2107C10.4876 9.43619 11.2198 8.08134 11.2198 6.47696C11.2198 3.91696 9.4068 2.02786 6.85458 2.02786C4.35516 2.02786 2.507 3.91696 2.507 6.47696C2.507 9.00165 4.35516 10.9084 6.85458 10.9084C7.12892 10.9084 7.39472 10.8864 7.65065 10.8439L8.86116 12.2679L8.9086 12.2525L8.86116 12.2679C9.66763 12.942 10.6345 13.97 12.0985 14.062L12.3111 14.0687C13.0679 14.0687 13.7896 13.9451 14.1768 13.7862L14.388 15.3045C13.9304 15.4634 13.1031 15.64 12.1878 15.64C9.3364 15.64 7.68185 13.027 6.83698 12.6386C3.35188 12.621 0.711647 9.99034 0.711647 6.47696C0.711647 2.92827 3.35188 0.279993 6.85458 0.279993ZM17.4584 0.456547V7.37737C17.4584 9.5313 18.5496 10.9437 20.8907 10.9437C23.2669 10.9437 24.3758 9.42537 24.3758 7.35972V0.456547H26.2063V7.50096C26.2063 10.2728 24.3934 12.6563 20.8907 12.6563C17.4232 12.6563 15.6278 10.5023 15.6278 7.50096V0.456547H17.4584ZM34.865 0.487247L39.6187 12.4928H37.77L36.7137 9.84449L33.7911 2.32339L30.7276 9.84449L29.636 12.4928H27.7874L32.7347 0.487247H34.865ZM43.5943 12.4621V3.3873L51.1982 12.4621H52.7999V0.456547H50.9694V9.56661L43.3655 0.456547H41.7638V12.4621H43.5943ZM61.2739 12.4621V2.08082H65.3575V0.456547H55.1486V2.08082H59.4434V12.4621H61.2739ZM72.793 12.6563C76.2957 12.6563 78.1086 10.2728 78.1086 7.50096V0.456547H76.2781V7.35972C76.2781 9.42537 75.1692 10.9437 72.793 10.9437C70.452 10.9437 69.3607 9.5313 69.3607 7.37737V0.456547H67.5301V7.50096C67.5301 10.5023 69.3255 12.6563 72.793 12.6563ZM83.3263 12.4621V3.36965L87.8323 8.84275H88.202L92.7256 3.36965V12.4621H94.5561V0.456547H92.884L88.0259 6.33572L83.1503 0.456547H81.4958V12.4621H83.3263Z"
          fill="white"
        />
      </SvgIcon>
    </Box>
  );
});
