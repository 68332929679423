import styled from '@emotion/styled';

const HeaderActionButton = styled('button')({
  background: 'none',
  border: 'none;',
  padding: 5,
  cursor: 'pointer',
  height: 20,
  '& svg': { width: 20, height: 20 },
});

export default HeaderActionButton;
