import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { omit } from "lodash";

import { widgetsApi } from "../../../api";
import { createLoadingAsyncThunk } from "../utils";
import { UISelectors } from "../ui";
import * as WidgetSelectors from "./selectors";

export const onRemoveHomeWidget = createLoadingAsyncThunk(
  "widgets/onRemoveHomeWidget",
  async (id, { getState }) => {
    const homeWidgets = WidgetSelectors.getWidgets(getState());
    const widgets = homeWidgets.filter((widget) => widget.id !== id);

    await widgetsApi.deleteUserWidget(id);

    return { widgets };
  }
);

export const onAddHomeWidget = createLoadingAsyncThunk(
  "widgets/onAddHomeWidget",
  async (_, { getState }) => {
    const chatWidgets = UISelectors.getChatWidgets(getState());
    const homeWidgets = WidgetSelectors.getWidgets(getState());
    const pinWidget = chatWidgets[0];

    await widgetsApi.createWidget(omit(pinWidget, ["data"]));

    return { widgets: homeWidgets.concat(pinWidget) };
  }
);

export const onAddApplicationWidget = createLoadingAsyncThunk(
  "widgets/onAddApplicationWidget",
  async (widget, { getState }) => {
    const copyWidget = { ...widget, id: uuidv4() }; // TODO: temp solution
    const homeWidgets = WidgetSelectors.getWidgets(getState());

    await widgetsApi.createWidget(omit(copyWidget, ["data"]));

    return { widgets: homeWidgets.concat(copyWidget) };
  }
);

export const getWidget = createAsyncThunk(
  "widgets/getWidget",
  async (id, { signal }) => {
    const { data } = await widgetsApi.getWidget(id, signal);
    return data;
  }
);

export const getWidgets = createLoadingAsyncThunk(
  "widgets/getWidgets",
  async () => {
    const { data } = await widgetsApi.getWidgets();
    return { widgets: data || [] };
  }
);

export const setUserWidgets = createLoadingAsyncThunk(
  "widgets/setUserWidgets",
  async (_, { getState }) => {
    const widgets = WidgetSelectors.getWidgets(getState());
    const { data } = await widgetsApi.setUserWidgets({
      widgets: widgets.map((widget) => widget.id),
    });

    return data;
  }
);

export const onPinCategoryWidget = createLoadingAsyncThunk(
  "widgets/onPinCategoryWidget",
  async (widgetId, { getState }) => {
    const homeWidgets = WidgetSelectors.getWidgets(getState());
    const pinWidget = UISelectors.getCategoryWidgetById(widgetId)(getState());

    await widgetsApi.createWidget(omit(pinWidget, ["data"]));

    return { widgets: homeWidgets.concat(pinWidget) };
  }
);

export const getCategoryWidgets = createLoadingAsyncThunk(
  "widgets/getCategoryWidgets",
  async (category, { signal }) => {
    const { data } = await widgetsApi.getCategoryWidgets(category, signal);
    return data;
  }
);
