import { api } from "./api";

export const sendMessage = (body, queryParam, signal) => {
  const query = new URLSearchParams(queryParam);

  return api.post(`/messages/send?${query}`, body, { signal });
};

export const likeMessage = (messageId) =>
  api.post(`/messages/${messageId}/like`);

export const dislikeMessage = (messageId, body) =>
  api.post(`/messages/${messageId}/dislike`, body);

export const sendMessageDashboard = async (body) => {
  try {
    const response = await fetch(process.env.REACT_APP_VERTEX_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_VERTEX_API_KEY}`,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch answer");
    }
    return await response.json();
  } catch (e) {
    console.log(e);
  }
};

export const sendMessageVolumeSimulator = async (body) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_VERTEX_API_URL + "/volumeSimulator",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_VERTEX_API_KEY}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch answer");
    }
    return await response.json();
  } catch (e) {
    console.log(e);
  }
};
