export const isMarkerVisible = (markerCoords, currentMapBounds) => {
  const markerLng = markerCoords[0];
  const markerLat = markerCoords[1];
  var lng =
    (markerLng - currentMapBounds._ne.lng) *
      (markerLng - currentMapBounds._sw.lng) <
    0;
  var lat =
    (markerLat - currentMapBounds._ne.lat) *
      (markerLat - currentMapBounds._sw.lat) <
    0;
  return lng && lat;
};
