import { configureStore } from "@reduxjs/toolkit";
import { combineAppReducer } from "@dpdgroupuk/ai-app";
import logger from "redux-logger";

import { MessagesReducer } from "./reducers/messages";
import { UIReducer } from "./reducers/ui";

import volumeSimStore from "../products/volume-sim/reducers";

// Already has app, topics and user slices from @dpdgroupuk/ai-app

const reducers = combineAppReducer({
  messages: MessagesReducer,
  ui: UIReducer,
  ...volumeSimStore,
});

const rootReducers = (state, action) => {
  if (action.type === "RESET_APP_STATE") {
    state = {
      app: { isLoading: false },
    };
  }

  return reducers(state, action);
};

// const isDevMode = import.meta.env.NODE_ENV !== "production";
const isDevMode = "production";

export default configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => {
    if (isDevMode) {
      return getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(logger);
    }

    return getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });
  },
  devTools: false,
});
