export const CARD_TYPES = {
  // FMP: 'FMP',
  // MAP: 'MAP',
  // COMMODITY: 'COMMODITY',
  // EORI: 'EORI',
  // SERVICES: 'SERVICES',
  // VAT: 'VAT',
  // LEAD_TIME: 'LEAD_TIME',
  // ABOUT_US: 'ABOUT_US',
  // DUTIES_TAX: 'DUTIES_TAX',
  // ELECTRONIC_CUSTOMS_DATA: 'ELECTRONIC_CUSTOMS_DATA',

  //MyDPD widgets
  // CEO_UPDATES: 'CEO_UPDATES',
  // DAILY_BULLETIN: 'DAILY_BULLETIN',

  //Chart widgets
  LINE_CHART: "LINE_CHART",
  BAR_CHART: "BAR_CHART",
  PIE_CHART: "PIE_CHART",
  SCATTER_CHART: "SCATTER_CHART",
  COMBO_CHART: "COMBO_CHART",

  PT_POLICY: "PT_POLICY",

  TABLE: "TABLE",

  APP_DOCS_PT: "APP_DOCS_PT",
  APP_DOCS_IT: "APP_DOCS_IT",
  APP_DOCS_LEGAL: "APP_DOCS_LEGAL",
  APP_DOCS_HS: "APP_DOCS_HS",
  APP_DOCS_SALES_AND_CRM: "APP_DOCS_SALES_AND_CRM",
  APP_DOCS_FLEET_AND_TRANSPORT: "APP_DOCS_FLEET_AND_TRANSPORT",
  APP_DOCS_INTERNATIONAL: "APP_DOCS_INTERNATIONAL",
  APP_DOCS_FINANCIAL: "APP_DOCS_FINANCIAL",
  APP_DOCS_ALL_DOCS: "APP_DOCS_ALL_DOCS",
  APP_DOCS_HELPER: "APP_DOCS_HELPER",
  APP_ANALYTICS_CRM: "APP_ANALYTICS_CRM",
  APP_ANALYTICS_CUSTOMER_PROFIT: "APP_ANALYTICS_CUSTOMER_PROFIT",
  APP_ANALYTICS_CUSTOMER_COMPLIANCE: "APP_ANALYTICS_CUSTOMER_COMPLIANCE",
  APP_ANALYTICS_CUSTOMER_FEEDBACK: "APP_ANALYTICS_CUSTOMER_FEEDBACK",
  APP_ANALYTICS_CUSTOMER_DELIVER_SERV: "APP_ANALYTICS_CUSTOMER_DELIVER_SERV",
  APP_ANALYTICS_CUSTOMER_COLLECT_SERV: "APP_ANALYTICS_CUSTOMER_COLLECT_SERV",
  APP_ANALYTICS_CUSTOMER_GREEN_STAT: "APP_ANALYTICS_CUSTOMER_GREEN_STAT",
  APP_ANALYTICS_CRM_FOCUS_REPORTS: "APP_ANALYTICS_CRM_FOCUS_REPORTS",
  APP_ANALYTICS_ALL_FIELDS: "APP_ANALYTICS_ALL_FIELDS",
  APP_ANALYTICS_HELPER: "APP_ANALYTICS_HELPER",
};

export const SNACKBAR_TRIGGER_COUNT = 5;
export const HOME_MANUAL_WIDTH_MAX_ITEMS = 6;
