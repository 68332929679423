const spaces = {
  small: 4,
  base: 8,
  medium: 12,
  large: 16,
  xLarge: 24,
  xxLarge: 32,
  xxxLarge: 64,
};

const radius = {
  xSmall: 2,
  small: 4,
  base: 8,
  medium: 12,
  large: 16,
  xLarge: 22,
  xxLarge: 24,
};

const card = {
  paddingVertical: spaces.large,
  paddingHorizontal: 20,
  bodyLineHeight: 20,
  contentGap: spaces.base,
  borderRadius: radius.medium,
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
};

const home = {
  paddingHorizontal: 92,
  paddingVertical: spaces.xxLarge,
  columnGap: spaces.xLarge,
  card: {
    minWidth: 300,
    maxWidth: 400,
    height: 430,
    chartHeight: 316,
  },
};

const layout = {
  headerHeight: 68,
  sidebarWidth: 356,
};

const chatInput = {
  height: 52,
  pBottom: 48,
};

const chat = {
  switchButtonsHeight: 56,
  input: chatInput,
  inputContainer: {
    height: spaces.xxLarge + chatInput.pBottom + chatInput.height,
    mobHeight: spaces.large + spaces.xxLarge + chatInput.height,
  },
};

const palette = {
  brandRed: '#DC0032',
  infographicRed: '#F2716C',
  torchRed: '#FE253B',
  tableRed: '#E54B5A',
  primaryMain: '#DC0033',
  primaryMain04: 'rgba(220, 0, 51, 0.04)',
  primary50: 'rgba(220, 0, 51, 0.50)',
  brandWhite: '#FFFFFF',
  brandBlack: '#414042',
  brandBlack60: 'rgba(65, 64, 66, 0.6)',
  brandBlack80: 'rgba(65, 64, 66, 0.80)',
  brandBlack38: 'rgba(65, 64, 66, 0.38)',
  brandLightGrey: '#E6E7E8',
  brandMidGrey: '#808285',
  warmGrey: '#CAC4BE',
  warmGrey05: '#F0F1F1',
  warmGrey10: '#FAF9F8',
  warmGrey20: '#F4F3F2',
  warmGrey30: '#fafafa',
  warmGrey40: '#EAE7E5',
  warmGrey50: '#E4E1DE',
  warmGrey80: '#CCCCCC',
  warmGrey90: '#BBBBBB',
  lightGrey: '#DDD8D5',
  textBlack: '#2D2D2D',
  green: '#38BC7C',
  success: '#2E7D32',
  blue: '#438ded',
  white50: 'rgb(255 255 255 / 50%)',
  white70: 'rgba(255, 255, 255, 0.70)',
  black54: 'rgba(0, 0, 0, 0.54)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black12: 'rgba(0, 0, 0, 0.12)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black38: 'rgba(0, 0, 0, 0.38)',
  black40: 'rgba(0, 0, 0, 0.4)',
  gray: '#808080',
  gray10: '#E6E6E6',
  gray50: '#7F7F7F',
  gray70: '#4C4C4C',
  gray80: '#333333',
  gray95: '#F2F2F2',
  gray300: '#E0E0E0',
  textSecondary: 'rgba(65, 64, 66, 0.8)',
  borderGray: 'rgba(65, 64, 66, 0.12)',
};

export { spaces, home, layout, chat, card, palette, radius };
