import { createAsyncThunk } from "@reduxjs/toolkit";
import { createLoadingAsyncThunk } from "../utils";

import { authApi, userApi } from "../../../api";

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  const { data } = await userApi.getUser();

  return data;
});

export const loginUser = createLoadingAsyncThunk("user/login", async (code) => {
  const { data } = await authApi.login(code);

  return data;
});

export const logoutUser = createAsyncThunk(
  "user/logout",
  async (_, { dispatch }) => {
    dispatch({ type: "RESET_APP_STATE" });

    return authApi.logout();
  }
);
