import styled from "@emotion/styled";
import Card from "../widgets/ContentCard";

const HeaderTitle = styled(Card.HeaderTitle)(({ variant }) => ({
  fontSize: variant === "small" ? 9 : 12,
  lineHeight: variant === "small" ? "normal" : "20px",
}));

const Title = styled(Card.Title)(({ variant }) => ({
  fontSize: variant === "small" ? 14 : 16,
  lineHeight: variant === "small" ? "normal" : "160%",
}));

/**
 * @param variant can be "small" or empty
 */
const CardTitle = ({ variant, headerTitle, title }) => (
  <Card.HeaderTitles>
    <HeaderTitle variant={variant}>{headerTitle}</HeaderTitle>
    <Title variant={variant}>{title}</Title>
  </Card.HeaderTitles>
);

export default CardTitle;
