import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppActions } from "@dpdgroupuk/ai-app";
import { isEqual } from "lodash";

import { setMapBounds } from "../map";
import { setBoundariesForSimulationMap } from "../depot";

import { getDepotBounds } from "../../utils/map/getDepotBounds";
import { formatDate } from "../../utils/date";
import { checkFirstTimeBand } from "../../utils/map/timeBands";
import { DEFAULT_TIMEBAND } from "../../constants/timeBands";
import { groupByPostcodeLevel } from "../../utils/groupByPostcodeLevel";
import {
  simulateData,
  getBoundariesAndActiveOverlayDates,
} from "../../utils/api";
import {
  defaultOverlayDataSelector,
  depotCodeListSelector,
  volumeOverlayDateSelector,
} from "../app/selector";

const { setLoading } = AppActions;

export const handleVolumeSimulatorDepotChange = createAsyncThunk(
  "vs_app/handleVolumeSimulatorDepotChange",
  async (
    { depotNameList, depotCodeList, districtAndSectorList },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState();
      const currentDepotCodeList = depotCodeListSelector(state);

      if (!districtAndSectorList) districtAndSectorList = [];

      // No need to re-call APIs if the Depot doesn't change
      // Always re-call if districtAndSectorList detected
      if (
        !isEqual([...depotCodeList].sort(), [...currentDepotCodeList].sort()) ||
        districtAndSectorList.length > 0
      ) {
        const getDepotBoundariesAndDates =
          await getBoundariesAndActiveOverlayDates(depotCodeList);
        const activeOverlayDates = Object.values(
          getDepotBoundariesAndDates.data
        )
          .map(({ overlayDates }) => overlayDates)
          .flat();

        const depotBoundaries = Object.values(
          getDepotBoundariesAndDates.data
        ).map(({ boundaries, depotCode }) =>
          boundaries.map((boundary) => ({ ...boundary, depotCode }))
        );

        // Split the boundaries up into different arrays based on the type of feature
        let groupedBoundaries = groupByPostcodeLevel(depotBoundaries);

        dispatch(
          setMapBounds(getDepotBounds(groupedBoundaries.districtBoundaries))
        );
        dispatch(setBoundariesForSimulationMap(groupedBoundaries));

        let formattedDepotNameList = [];
        for (let i = 0; i < depotCodeList.length; i++) {
          const depotName = depotNameList[i];
          const depotCode = depotCodeList[i];
          formattedDepotNameList.push(`${depotName} (${depotCode})`);
        }

        return {
          depotCodeList,
          depotNameList: formattedDepotNameList,
          activeOverlayDates,
        };
      }
    } catch (e) {
      dispatch(setLoading(false));
      return rejectWithValue("Getting Depot Information");
    }
  }
);

const getDefaultSimulationData = async (
  currentOverlayDate,
  depotCode,
  depotCodeList,
  newDate,
  districtAndSectorList
) => {
  const formattedDate = formatDate(newDate);
  if (formattedDate !== currentOverlayDate) {
    const defaultOverlayData = await simulateData(
      depotCodeList,
      formattedDate,
      0,
      districtAndSectorList
    );
    if (defaultOverlayData.data.length < 1) {
      throw new Error();
    } else {
      const firstBand = checkFirstTimeBand(
        "00:00:00",
        defaultOverlayData.data,
        formattedDate
      );
      const overlayTimeBand =
        firstBand > DEFAULT_TIMEBAND ? firstBand : DEFAULT_TIMEBAND;

      return {
        defaultOverlayData: defaultOverlayData.data,
        overlayTimeBand,
        volumeOverlayDate: formattedDate,
      };
    }
  }
};

// export const getPostcodeAllocationData = async (date) => {
//   const fullMapParcelList = await getParcelDataForFullMap(date);
//   dispatch(setFullMapOverlayDate(date));
//   dispatch(setFullMapParcelList(fullMapParcelList.data));
// };

export const handleVolumeSimulatorDateChange = createAsyncThunk(
  "vs_app/handleVolumeSimulatorDate",
  async (
    { date, depotCode, depotCodeList, districtAndSectorList = [] },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState();
      const currentOverlayDate = volumeOverlayDateSelector(state);

      return await getDefaultSimulationData(
        currentOverlayDate,
        depotCode,
        depotCodeList,
        date,
        districtAndSectorList
      );

      // ! Ignoring postcode allocation for now - in Oracle
      // if (pageToShowIndex === 0) {
      //   await getDefaultSimulationData(date, depotCode, districtAndSectorList);
      // } else if (pageToShowIndex === 1) {
      //   await getPostcodeAllocationData(date);
      // }
      // }
    } catch (e) {
      dispatch(setLoading(false));
      return rejectWithValue("Getting Default Overlay Data");
    }
  }
);

export const handleSimulation = createAsyncThunk(
  "vs_app/handleSimulation",
  async (
    { percentage, depotCodeList, date, districtAndSectorList = [] },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState();
      const currentDefaultOverlayData = defaultOverlayDataSelector(state);
      let simulatedData = { data: {} };
      if (percentage === 0 || percentage === null) {
        // The default file for percentage value 0 already gets made when you select the overlay date so no need to run simulation on it again
        simulatedData = null;
      } else {
        // Simulating a percentage (above 0) does not return default data with it so default data must be setup first if it's not in redux store already
        // Should only be the case when running through vertex chat
        if (
          !currentDefaultOverlayData ||
          currentDefaultOverlayData.length < 1
        ) {
          await getDefaultSimulationData(
            date,
            depotCodeList,
            districtAndSectorList
          );
        }
        simulatedData = await simulateData(
          depotCodeList,
          date,
          percentage,
          districtAndSectorList
        );
        simulatedData.data.percentage = percentage;
      }
      return {
        simulatedVolumeOverlayData: simulatedData.data,
        simulationPercentage: percentage,
      };
    } catch (e) {
      dispatch(setLoading(false));
      return rejectWithValue("Simulating Data");
    }
  }
);
