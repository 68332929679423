import { makeStyles } from "@mui/styles";
import { palette } from "../../../../styles/palette";

const useStyles = makeStyles({
  legend: {
    // bottom: "10px",
    // right: "10px",
    bottom: "10px",
    right: "10px",
    position: "absolute",
    zIndex: "100",
    display: "flex",
    gap: "3px",
  },
  itemContainer: {
    // width: "25px",
    width: "30px",
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  box: {
    display: "inline-block",
    height: "13px",
    width: "13px",
    border: `1px solid ${palette.typography.black.inactive}`,
  },
  text: {
    color: palette.typography.white.high,
    fontSize: "12px",
    paddingTop: "5px",
  },
});

export const HeatmapLegend = () => {
  const styles = useStyles();
  const heatmapMapping = {
    50: palette.heatmap[0],
    100: palette.heatmap[1],
    150: palette.heatmap[2],
    200: palette.heatmap[3],
    250: palette.heatmap[4],
    "250+": palette.heatmap[5],
  };

  return (
    <div className={styles.legend}>
      {Object.keys(heatmapMapping).map((value, index) => (
        <div key={index} className={styles.itemContainer}>
          <div
            className={styles.box}
            style={{ backgroundColor: heatmapMapping[value] }}
          />
          <div className={styles.text}>{value}</div>
        </div>
      ))}
    </div>
  );
};
