import bboxPolygon from "@turf/bbox-polygon";
import centerOfMass from "@turf/center-of-mass";
import { featureCollection } from "@turf/helpers";
import { isMarkerVisible } from "./isMarkerVisible";

export const getCenterOfDepot = (boundaryData) => {
  if (boundaryData.length === 0 || !boundaryData) {
    throw new Error("Boundary data cannot be empty / Must be an array");
  }
  const polygons = [];

  boundaryData.forEach(({ box_coordinates }) => {
    polygons.push(bboxPolygon(box_coordinates));
  });

  // STEP 1 - Get Center of Depot Boundary and get Closest sector boundary to the center (in case the depot center falls outside a sector)
  let getCenterOfMass = centerOfMass(featureCollection(polygons));

  const centerCoords = getCenterOfMass.geometry.coordinates;

  let isCenterPointInsideDepot = false;

  let closestDistrict = getDistrictCenterClosestToDepotCenter(
    centerCoords,
    boundaryData
  );

  // STEP 2 - Check if Center is within one of the Sectors (The bounding box is a square around the sector layers)
  boundaryData.forEach(({ box_coordinates }) => {
    // ne = North East & sw = South West
    const swLng = box_coordinates[0];
    const swLat = box_coordinates[1];
    const neLng = box_coordinates[2];
    const neLat = box_coordinates[3];

    const mapBounds = {
      _ne: { lat: neLat, lng: neLng },
      _sw: { lat: swLat, lng: swLng },
    };

    if (isMarkerVisible(centerCoords, mapBounds)) {
      isCenterPointInsideDepot = false;
    }
  });

  // If the center isn't inside depot then set to the cloest sector box coords (to the depot center)
  if (!isCenterPointInsideDepot) {
    getCenterOfMass = centerOfMass(bboxPolygon(closestDistrict));
  }

  return getCenterOfMass.geometry.coordinates;
};

const getDistrictCenterClosestToDepotCenter = (depotCenter, boundaryData) => {
  let currentClosest = -1;
  let currentCoords;
  boundaryData.forEach(({ box_coordinates }) => {
    const bbox = bboxPolygon(box_coordinates);
    const getBoundaryCenter = centerOfMass(bbox);
    const boundaryCenter = getBoundaryCenter.geometry.coordinates;

    const distance = calculateDistanceBetweenLongLatPoints(
      depotCenter[1],
      depotCenter[0],
      boundaryCenter[1],
      boundaryCenter[0]
    );

    if (distance < currentClosest || currentClosest < 0) {
      currentClosest = distance;
      currentCoords = box_coordinates;
    }
  });
  return currentCoords;
};

const calculateDistanceBetweenLongLatPoints = (neLng, neLat, swLng, swLat) => {
  let R = 6371; // km
  let dLat = toRad(swLat - neLat);
  let dLon = toRad(swLng - neLng);
  neLat = toRad(neLat);
  swLat = toRad(swLat);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(neLat) * Math.cos(swLat);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d;
};

// Converts numeric degrees to radians
const toRad = (Value) => (Value * Math.PI) / 180;
