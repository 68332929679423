import React from "react";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";

import { handleVolumeSimulatorDateChange } from "../../../reducers/app";
import {
  activeDatesForAllocationDatePickerSelector,
  activeDatesForSimulationDatePickerSelector,
  fullMapOverlayDateSelector,
  pageToShowIndexSelector,
  volumeOverlayDateSelector,
  depotCodeListSelector,
} from "../../../reducers/app/selector";
import "react-calendar/dist/Calendar.css";
import { formatDate } from "../../../utils/date";

import "./HeaderDatePicker.css";

export const HeaderDatePicker = ({
  activeDropDown,
  setActiveDropDown,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const volumeOverlayDate = useSelector(volumeOverlayDateSelector);
  const depotCodeList = useSelector(depotCodeListSelector);
  const pageToShowIndex = useSelector(pageToShowIndexSelector);
  const activeSimulationDates = useSelector(
    activeDatesForSimulationDatePickerSelector
  );
  const activeAllocationDates = useSelector(
    activeDatesForAllocationDatePickerSelector
  );
  const fullMapOverlayDate = useSelector(fullMapOverlayDateSelector);

  const activeDates =
    pageToShowIndex === 0 ? activeSimulationDates : activeAllocationDates;

  const maxDate = activeDates[0]
    ? new Date(activeDates[activeDates.length - 1])
    : new Date();

  const minDate = activeDates[0] ? new Date(activeDates[0]) : new Date();

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="headerDatePicker"
    >
      <Calendar
        onChange={async (newDate) => {
          dispatch(setLoading(true));
          setActiveDropDown("none");
          // Ignore await message - await IS needed here otherwise loading screen will disappear instantly
          await dispatch(
            handleVolumeSimulatorDateChange({
              date: newDate,
              depotCodeList,
            })
          );
          dispatch(setLoading(false));
        }}
        defaultActiveStartDate={maxDate}
        value={
          new Date(
            pageToShowIndex === 0 ? volumeOverlayDate : fullMapOverlayDate
          )
        }
        isOpen={activeDropDown === "volume"}
        maxDate={maxDate}
        minDate={minDate}
        tileDisabled={({ date, view }) => {
          if (view === "month") {
            return !activeDates.includes(formatDate(date));
          } else {
            return false;
          }
        }}
      />
    </div>
  );
};
