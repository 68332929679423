import styled from '@emotion/styled';

const ActionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

const CardActions = ({ children, className }) => (
  <ActionContainer className={className}>{children}</ActionContainer>
);

export default CardActions;
