export const sectorBoundariesSelector = (state) => state.depot.sectorBoundaries;
export const districtBoundariesSelector = (state) =>
  state.depot.districtBoundaries;
export const sectorBoundariesForMutlipleDepotsSelector = (state) =>
  state.depot.sectorBoundariesForMultipleDepots;
export const districtBoundariesForMutlipleDepotsSelector = (state) =>
  state.depot.districtBoundariesForMultipleDepots;
export const allBoundariesForMultipleAreasSelector = (state) =>
  state.depot.allBoundariesForMultipleAreas;
export const businessUnitFilterSelector = (state) =>
  state.depot.businessUnitFilter;
