import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  onRemoveHomeWidget,
  onAddHomeWidget,
  getWidget,
  getWidgets,
  onPinCategoryWidget,
  onAddApplicationWidget,
} from "./actions.js";

import { CARD_TYPES } from "../../../constants/cards.js";
import { ALL_APPLICATIONS_WIDGETS } from "../../../constants/applicationCards.js";

const initialState = [];

const widgetsSlice = createSlice({
  name: "widgets",
  initialState,
  reducers: {
    setWidgets: (state, action) => action.payload,
  },
  extraReducers(builder) {
    builder.addCase(getWidget.fulfilled, (state, action) => {
      const { id } = action.payload;
      const index = state.findIndex((widget) => widget.id === id);

      state[index] = action.payload;
    });
    builder.addCase(getWidgets.fulfilled, (state, action) => {
      const { widgets } = action.payload;
      const widgetsProcessed = widgets.map((widget) => {
        if (ALL_APPLICATIONS_WIDGETS[widget.type]) {
          const applicationWidget = ALL_APPLICATIONS_WIDGETS[widget.type];

          widget.data = applicationWidget.data;
        }

        return widget;
      });

      const isDifferent =
        state.length !== widgets.length ||
        state.some((widget, i) => widget.id !== widgets[i].id);

      return isDifferent ? widgetsProcessed : state;
    });
    builder.addMatcher(
      isAnyOf(
        onRemoveHomeWidget.fulfilled,
        onAddHomeWidget.fulfilled,
        onPinCategoryWidget.fulfilled,
        onAddApplicationWidget.fulfilled
      ),
      (state, action) => action.payload.widgets || []
    );
  },
});

export const actions = widgetsSlice.actions;

export default widgetsSlice;
