export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  CHAT: '/chat',
  CHAT_TOPIC: '/chat/:topicId?',
  // NEWS: '/news',
  // NEWS_CATEGORY: '/news/:category',
  DOCS: '/docs',
  DOCS_CATEGORY: '/docs/:category',
  ANALYTICS: '/analytics',
  ANALYTICS_CATEGORY: '/analytics/:category',
  CUSTOMER_SERVICES: '/customer-services',
};

export const PAGE_TITLES = {
  HOME: 'DPD Oracle',
  CHAT: 'DPD Oracle Chat',
  SUB_NAV_DOCS: 'Business Documents',
  PT_POLICY: 'P&T Documents',
  IT_POLICY: 'IT Documents',
  CRM: 'CRM Volume & Revenue',
  SUB_NAV_NEWS: 'DPD News',
  SUB_NAV_ANALYTICS: 'Analytics',
  SUB_NAV_UNIVERSE: 'UniVerse',
  SUB_NAV_QUANTUM: 'Quantum',
  SUB_NAV_SALESFORCE: 'Salesforce',
  SUB_NAV_GATEKEEPER: 'Gatekeeper',
  SUB_NAV_AGRESSO: 'Agresso',
  SUB_NAV_SUSTAINABILITY: 'Sustainability',
  SUB_NAV_INNOVATION: 'Innovation',
  CUSTOMER_SERVICES: 'Customer Services',
};

export const SUB_NAV_ROUTES = {
  NEWS: 'news',
  DOCS: 'docs',
  ANALYTICS: 'analytics',
  CUSTOMER_SERVICES: 'customer-services',
};

export const WIDGET_CATEGORIES = {
  PT_POLICY: 'pt-policy',
  IT_POLICY: 'it-policy',
  CRM: 'crm',
};

export const PAGE_TITLE_ROUTE_MAP = [
  {
    routeName: ROUTES.HOME,
    pageTitle: PAGE_TITLES.HOME,
  },
  {
    routeName: ROUTES.CHAT,
    pageTitle: PAGE_TITLES.CHAT,
  },
  {
    routeName: ROUTES.CHAT_TOPIC,
    pageTitle: PAGE_TITLES.CHAT,
  },
  {
    routeName: SUB_NAV_ROUTES.NEWS,
    pageTitle: PAGE_TITLES.SUB_NAV_NEWS,
  },
  {
    routeName: SUB_NAV_ROUTES.DOCS,
    pageTitle: PAGE_TITLES.SUB_NAV_DOCS,
  },
  {
    routeName: SUB_NAV_ROUTES.DOCS,
    pageTitle: PAGE_TITLES.SUB_NAV_DOCS,
  },
  {
    routeName: `${SUB_NAV_ROUTES.DOCS}/${WIDGET_CATEGORIES.PT_POLICY}`,
    pageTitle: PAGE_TITLES.PT_POLICY,
  },
  {
    routeName: `${SUB_NAV_ROUTES.DOCS}/${WIDGET_CATEGORIES.IT_POLICY}`,
    pageTitle: PAGE_TITLES.IT_POLICY,
  },
  {
    routeName: SUB_NAV_ROUTES.ANALYTICS,
    pageTitle: PAGE_TITLES.SUB_NAV_ANALYTICS,
  },
  {
    routeName: `${SUB_NAV_ROUTES.ANALYTICS}/${WIDGET_CATEGORIES.CRM}`,
    pageTitle: PAGE_TITLES.CRM,
  },
  {
    routeName: SUB_NAV_ROUTES.CUSTOMER_SERVICES,
    pageTitle: PAGE_TITLES.CUSTOMER_SERVICES,
  },
];

export const RIGHT_NAVIGATION_ITEMS = [
  // {
  //   text: PAGE_TITLES.SUB_NAV_NEWS,
  //   icon: null,
  //   routeName: SUB_NAV_ROUTES.NEWS,
  // },
  {
    text: PAGE_TITLES.SUB_NAV_DOCS,
    icon: null,
    routeName: SUB_NAV_ROUTES.DOCS,
  },
  {
    text: PAGE_TITLES.SUB_NAV_ANALYTICS,
    icon: null,
    routeName: SUB_NAV_ROUTES.ANALYTICS,
  },
  {
    text: PAGE_TITLES.CUSTOMER_SERVICES,
    icon: null,
    routeName: SUB_NAV_ROUTES.CUSTOMER_SERVICES,
  },
  {
    text: PAGE_TITLES.SUB_NAV_UNIVERSE,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_QUANTUM,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_SALESFORCE,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_GATEKEEPER,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_AGRESSO,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_SUSTAINABILITY,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_INNOVATION,
    icon: null,
    routeName: null,
  },
];

export const SEARCH_WIDGET_ENABLED_ROUTES = [ROUTES.DOCS_CATEGORY];

export const ALLOWED_DOCS_PARAMS = [
  WIDGET_CATEGORIES.PT_POLICY,
  WIDGET_CATEGORIES.IT_POLICY,
];

export const ALLOWED_ANALYTICS_PARAMS = [WIDGET_CATEGORIES.CRM];
