import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  districtBoundariesForMutlipleDepotsSelector,
  districtBoundariesSelector,
} from "../../reducers/depot/selector";
import ReactMapboxGL from "react-mapbox-gl";
import mapboxgl from "mapbox-gl";
import { useDispatch } from "react-redux";
import { Sources } from "./components/MapperSources/MapperSources";
import { Layers } from "./components/MapperLayers/MapperLayers";
import { HeatmapLegend } from "./components/HeatmapLegend";
import { TimeBandSlider } from "./components/TimeBandSlider";
import {
  defaultOverlayDataSelector,
  pageToShowIndexSelector,
} from "../../reducers/app/selector";
import { setIsMapAdjusting, setMapLevel } from "../../reducers/map";
import { ZOOM_LEVELS } from "../../constants/map";
import { DEPOT, OUTER, SECTOR } from "../../constants/layers";
import { mapBoundsSelector } from "../../reducers/map/selector";
import "mapbox-gl/dist/mapbox-gl.css";
// import { groupByPostcodeLevel } from "../../utils/groupByPostcodeLevel";
import "./Mapper.css";

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = MapboxWorker;

const accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

const Map = ReactMapboxGL({
  accessToken,
  attributionControl: false,
});

export const Mapper = () => {
  const [map, setMap] = useState();
  const dispatch = useDispatch();
  const getSingledistrictBoundaries = useSelector(districtBoundariesSelector);
  const getMultipleDistrictBoundaries = useSelector(
    districtBoundariesForMutlipleDepotsSelector
  );
  const defaultOverlayList = useSelector(defaultOverlayDataSelector);
  const mapBounds = useSelector(mapBoundsSelector);
  const pageToShowIndex = useSelector(pageToShowIndexSelector);
  const mapStyle = {
    style: "mapbox://styles/mapbox/dark-v10?optimize=true",
    attributionControl: false,
  };

  const onMapZoom = (zoom) => {
    if (zoom >= ZOOM_LEVELS.SECTOR) {
      dispatch(setMapLevel(SECTOR));
    } else if (zoom >= ZOOM_LEVELS.OUTER) {
      dispatch(setMapLevel(OUTER));
    } else {
      dispatch(setMapLevel(DEPOT));
    }
  };

  const onMapAdjust = () => {
    dispatch(setIsMapAdjusting((isMapAdjusting) => !isMapAdjusting));
  };

  let districtBoundaries;

  pageToShowIndex === 0
    ? (districtBoundaries = getSingledistrictBoundaries)
    : (districtBoundaries = getMultipleDistrictBoundaries);

  return (
    <div className="mapContainer">
      <Map
        key={"map"}
        // eslint-disable-next-line react/style-prop-object
        {...mapStyle}
        fitBounds={mapBounds}
        onStyleLoad={(map) => {
          setMap(map);
        }}
        onZoomEnd={(map) => {
          onMapZoom(map.getZoom());
          onMapAdjust();
        }}
        onDragEnd={() => {
          onMapAdjust();
        }}
      >
        {districtBoundaries && districtBoundaries.length > 0 && (
          <>
            <Sources map={map} />
            <Layers map={map} />
          </>
        )}
        {defaultOverlayList &&
          defaultOverlayList.length > 0 &&
          pageToShowIndex === 0 && (
            <>
              <HeatmapLegend />
              <TimeBandSlider />
            </>
          )}
      </Map>
    </div>
  );
};
