import { Provider } from "@dpdgroupuk/ai-app";
import { Route } from "react-router-dom";
import { api } from "../api/api";
import { ROOT, VOLUME_SIM } from "../constants/routes";
import { HomePage } from "../pages/Home";
import { HomePage as VolumeSimulatorPage } from "../pages/VolumeSimulator";

const { REACT_APP_MAIN_PROJECT_URL, REACT_APP_MAIN_PROJECT_API } = process.env;

export const SiteRoutes = () => {
  return (
    <Provider
      axiosInstance={api}
      mainProjectUrl={REACT_APP_MAIN_PROJECT_URL}
      mainProjectApiUrl={REACT_APP_MAIN_PROJECT_API}
    >
      <Route path={ROOT} element={<HomePage />} />
      <Route path={VOLUME_SIM} element={<VolumeSimulatorPage />} />
    </Provider>
  );
};
