import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { fetchTopics } from '../topics/actions';
import { fetchMessagesByTopicId } from '../messages/actions.js';
import { onLaunchApp } from './actions.js';

const initialState = {
  notificationsCounter: 0,
  isLoading: true,
  isInitialLoading: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setNotificationsCounter: (state, action) => {
      state.notificationsCounter = action.payload;
    },
    decrementNotificationCounter: (state, action) => {
      state.notificationsCounter -= action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTopics.fulfilled, (state, action) => {
      state.notificationsCounter = action.payload.unreadCount;
    });
    builder.addCase(fetchMessagesByTopicId.pending, (state, action) => {
      state.activeTopic = action.meta.arg;
    });
    builder.addMatcher(
      isAnyOf(onLaunchApp.fulfilled, onLaunchApp.rejected),
      state => {
        state.isLoading = false;
        state.isInitialLoading = false;
      }
    );
  },
});

export const actions = appSlice.actions;

export default appSlice;
