import { Avatar, Box, Grid, IconButton } from "@mui/material";
import styled from "@emotion/styled";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import ReactMarkdown from "react-markdown";

import chatAvatar from "../../../../assets/avatar.svg";

// import Divider from "./Divider.jsx";

const UserMessageContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isUser",
})(({ isUser, theme }) => ({
  display: "flex",
  marginBottom: 32,
  padding: "0 20px",
  justifyContent: isUser ? "flex-end" : "flex-start",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    padding: 0,
  },
}));

const UserMessageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  position: "relative",
  backgroundColor: theme.colors.green,
  color: theme.colors.brandWhite,
  padding: "16px 24px",
  boxShadow: "0px 3px 5px 1px rgba(0, 0, 0, 0.2)",
  borderRadius: theme.radius.xxLarge,
  maxWidth: "80%",
  minHeight: 50,
  wordWrap: "break-word",
}));

const UserAvatar = styled(Avatar)({
  width: 48,
  height: 48,
  marginLeft: 10,
});

const ChatMessageBox = styled(UserMessageBox)(({ theme }) => ({
  backgroundColor: theme.colors.brandWhite,
  color: theme.colors.textBlack,
  padding: "16px 24px",
  justifyContent: "start",
  textAlign: "start",
}));

const ChatAvatar = styled(UserAvatar)({
  marginLeft: 10,
  marginRight: 10,
});

const ResponseFooter = styled(Grid)({
  paddingTop: 10,
  flexDirection: "row",
  justifyContent: "flex-end",
});

const MessageWrapper = styled(ReactMarkdown)(({ theme }) => ({
  "& a": {
    fontWeight: 700,
    color: theme.colors.blue,
  },
  "& code": {
    whiteSpace: "pre-wrap",
  },
}));

const ChatResponse = ({
  message,
  thumbUpClick,
  thumbDownClick,
  feedbackType,
  disableThumbs,
  isSystem,
}) => (
  <ChatMessageBox>
    <MessageWrapper
    // linkTarget={"_blank"}
    >
      {message}
    </MessageWrapper>

    {!isSystem && (
      <>
        {/* <Divider /> */}
        <ResponseFooter container>
          <IconButton onClick={thumbUpClick} disabled={disableThumbs}>
            {feedbackType === 1 ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
          </IconButton>
          <IconButton onClick={thumbDownClick} disabled={disableThumbs}>
            {feedbackType === 2 ? <ThumbDown /> : <ThumbDownAltOutlinedIcon />}
          </IconButton>
        </ResponseFooter>
      </>
    )}
  </ChatMessageBox>
);

const Message = ({
  message,
  isUser,
  thumbDownClick,
  thumbUpClick,
  feedbackType,
  picture,
  id,
  isSystem,
}) => (
  <UserMessageContainer item isUser={isUser}>
    {isUser ? (
      <>
        <UserMessageBox message={message}>{message}</UserMessageBox>
        {picture ? (
          <UserAvatar
            alt="User"
            src={picture}
            imgProps={{ referrerPolicy: "no-referrer" }}
          />
        ) : (
          <ChatAvatar alt="Chat" src={chatAvatar} />
        )}
      </>
    ) : (
      <>
        <ChatAvatar alt="Chat" src={chatAvatar} />
        <ChatResponse
          message={message}
          thumbDownClick={thumbDownClick}
          thumbUpClick={thumbUpClick}
          feedbackType={feedbackType}
          disableThumbs={(!!feedbackType && feedbackType !== 0) || !id}
          isSystem={isSystem}
        />
      </>
    )}
  </UserMessageContainer>
);

export default Message;
