import { useCallback, useMemo } from "react";
import styled from "@emotion/styled";

import ImageMedia from "../../assets/play.svg";
import ImageDOC from "../../assets/doc.svg";
import ImageDOCDisabled from "../../assets/docDisabled.svg";
import ImageDirectory from "../../assets/widgets/grid.svg";
import ImageDirectoryDisabled from "../../assets/widgets/grid_disabled.svg";
import { ACTION_BUTTON_TYPES } from "../../constants";
import {
  VIEW_DOCUMENT,
  LISTEN_TO_PODCAST,
  OPEN_DASHBOARD,
  OPEN_FOLDER,
} from "../../constants/strings";

const Container = styled("div")(({ theme, disabled }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spaces.base,
  alignSelf: "stretch",

  padding: `4px 10px`,
  border: `1px solid ${
    disabled ? theme.colors.black12 : theme.colors.primary50
  }`,
  borderRadius: theme.radius.small,
  cursor: disabled ? "default" : "pointer",
  pointerEvents: disabled ? "none" : "all",

  "& > *": {
    fontFamily: theme.fonts.plutoRegular,
    color: theme.colors.brandBlack,
  },
  ":hover": {
    background: disabled ? "unset" : theme.colors.primaryMain04,
  },
}));

const Icon = styled("img")({
  width: 20,
  height: 20,
});

const Title = styled("div")(({ theme, disabled }) => ({
  fontFamily: theme.fonts.plutoRegular,
  color: disabled ? theme.colors.black38 : "#DC0033",
  fontSize: 13,
  fontWeight: "400",
  lineHeight: "20px",
  letterSpacing: 0.46,
  textTransform: "uppercase",
}));

const Description = styled("div")(({ theme }) => ({
  fontFamily: theme.fonts.plutoLight,
  fontSize: 12,
  lineHeight: "20px",
}));

const LinkItem = ({
  title,
  description,
  url,
  type,
  text,
  disabled,
  onClick,
}) => {
  const content = useMemo(() => {
    switch (type) {
      case ACTION_BUTTON_TYPES.DOCUMENT:
        return {
          image: disabled ? ImageDOCDisabled : ImageDOC,
          title: VIEW_DOCUMENT,
        };
      case ACTION_BUTTON_TYPES.MEDIA:
        return { image: ImageMedia, title: LISTEN_TO_PODCAST };
      case ACTION_BUTTON_TYPES.FOLDER:
        return {
          image: disabled ? ImageDirectoryDisabled : ImageDirectory,
          title: text || OPEN_FOLDER,
        };
      case ACTION_BUTTON_TYPES.DASHBOARD:
        return {
          image: disabled ? ImageDirectoryDisabled : ImageDirectory,
          title: OPEN_DASHBOARD,
        };
      default:
        return { image: ImageDOC, title: VIEW_DOCUMENT };
    }
  }, [type, disabled, text]);

  const onClickDefault = useCallback(() => {
    window.open(url, "_blank");
  }, [url]);

  const onItemClick = useCallback(() => {
    if (disabled) {
      return;
    }
    onClick ? onClick() : onClickDefault();
  }, [onClickDefault, disabled, onClick]);

  return (
    <Container onClick={onItemClick} disabled={disabled}>
      <Icon src={content.image} alt={type} />
      <div>
        <Title disabled={disabled}>{title || content.title}</Title>
        {description && <Description>{description}</Description>}
      </div>
    </Container>
  );
};

export default LinkItem;
