import { createSlice } from "@reduxjs/toolkit";

import { FEEDBACK_TYPE } from "../../../constants";
import { fetchMessagesByTopicId } from "./actions";

const initialState = [];

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action) => action.payload,
    savePendingMessage: (state, action) => [
      ...state,
      {
        question: action.payload,
        id: Date.now().toString(),
      },
    ],
    removePendingMessage: (state, action) => {
      //replace saved dummy question message with correct object from backend
      state[state.length - 1] = action.payload;
    },
    resetMessages: () => initialState,
    likeLocalMessage: (state, action) => {
      const index = state.findIndex((message) => message.id === action.payload);

      state[index].feedbackType = FEEDBACK_TYPE.LIKE;
    },
    dislikeLocalMessage: (state, action) => {
      const index = state.findIndex((message) => message.id === action.payload);

      state[index].feedbackType = FEEDBACK_TYPE.DISLIKE;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchMessagesByTopicId.fulfilled,
      (state, action) => action.payload
    );
  },
});

export default messagesSlice;
