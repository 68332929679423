const muiCssBaseline = {
  styleOverrides: {
    body: {
      scrollBehavior: 'smooth',
      scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
      scrollbarWidth: 'thin',
    },
  },
};

export default muiCssBaseline;
