import { createSelector } from '@reduxjs/toolkit';

export const getWidgets = state => state.widgets;
export const getWidgetById = id =>
  createSelector(getWidgets, widgets =>
    widgets.find(widget => widget.id === id)
  );
export const isWidgetsSortable = createSelector(
  getWidgets,
  widgets => widgets.length > 1
);
