import styled from '@emotion/styled';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  minHeight: 30,
});

const CardHeader = ({ children, className }) => (
  <Container className={className}>{children}</Container>
);

export default CardHeader;
