import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "@dpdgroupuk/ai-app";

import {
  // SideBarTabs,
  SimulationSideBar,
  // PostcodeAllocationSidebar,
} from "./components";
import {
  setSimulatedVolumeOverlayData,
  setDefaultOverlayData,
} from "../../reducers/app";
import { depotCodeListSelector } from "../../reducers/app/selector";
import "./Sidebar.css";

export const Sidebar = () => {
  const depotCodeList = useSelector(depotCodeListSelector);

  const { setLoading } = AppActions;

  const dispatch = useDispatch();

  // Reset list values if the depot changes to make it impossible for wrong previous simulated data to be displayed
  useEffect(() => {
    dispatch(setSimulatedVolumeOverlayData(null));
    dispatch(setDefaultOverlayData([]));
  }, [depotCodeList, dispatch]);

  return (
    <div className="sidebar">
      <SimulationSideBar setLoading={setLoading} />
      {/* {!process.env.DEV_ENV && <SideBarTabs />}
      {pageToShowIndex === 0 && ( // SIMULATION
        <SimulationSideBar />
      )}
      {pageToShowIndex === 1 && <PostcodeAllocationSidebar />} */}
    </div>
  );
};
