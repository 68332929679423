import { createSlice } from "@reduxjs/toolkit";
import { BUSINESS_UNITS } from "../../constants/businessUnits";

const initialState = {
  //SIMULATION
  sectorBoundaries: [],
  districtBoundaries: [],
  //POSTCODE ALLOCATION
  sectorBoundariesForMultipleDepots: [],
  districtBoundariesForMultipleDepots: [],
  allBoundariesForMultipleAreas: [],
  businessUnitFilter: 1,
};

export const depotSlice = createSlice({
  name: "depot",
  initialState,
  reducers: {
    setBoundariesForSimulationMap: (state, action) => {
      state.sectorBoundaries = action.payload.sectorBoundaries;
      state.districtBoundaries = action.payload.districtBoundaries;
    },
    setBoundariesForMultipleDepots: (state, action) => {
      state.sectorBoundariesForMultipleDepots = action.payload.sectorBoundaries;
      state.districtBoundariesForMultipleDepots =
        action.payload.districtBoundaries;
      state.allBoundariesForMultipleAreas = action.payload.allBoundaries;
    },
    setBusinessUnitFilter: (state, action) => {
      if (BUSINESS_UNITS.includes(action.payload)) {
        state.businessUnitFilter = action.payload;
      } else {
        state.businessUnitFilter = 1;
      }
    },
    resetDepotState: (state, action) => {
      state = initialState;
    },
  },
});

const { reducer, actions } = depotSlice;
const {
  setBoundariesForSimulationMap,
  setBoundariesForMultipleDepots,
  setBusinessUnitFilter,
  resetDepotState,
} = actions;
export {
  initialState,
  reducer,
  setBoundariesForSimulationMap,
  setBoundariesForMultipleDepots,
  setBusinessUnitFilter,
  resetDepotState,
};
