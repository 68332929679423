import React from "react";
import { useSelector } from "react-redux";
import { AppActions } from "@dpdgroupuk/ai-app";

import PageContainer from "../../components/grid/PageContainer.jsx";
import { UserSelectors } from "../../store";
import { VolumeSimulatorPage } from "../../products/volume-sim/index.js";

export const HomePage = () => {
  const { getUser } = UserSelectors;
  const otherUser = {
    ...useSelector(getUser),
    spannerUserId: process.env.REACT_APP_SPANNER_USER_ID,
  }; // TODO Get Spanner Id the proper way

  const { spannerUserId } = otherUser;

  const { setLoading } = AppActions;

  return (
    <PageContainer>
      <VolumeSimulatorPage
        setLoading={setLoading}
        spannerUserId={spannerUserId}
      />
    </PageContainer>
  );
};
