import { checkClosestTimeBand } from "../timeBands";
import { Postcode } from "../../postcode";
import { OUTER, SECTOR } from "../../../constants/layers";

export const setUpSimulationParcelList = (
  listType, // SECTOR or OUTER
  defaultOverlayDataList = [],
  simulatedDataList = [],
  overlayTimeBand,
  returnType // List or Total
) => {
  const list = [];
  let overlayStops = [];
  let allSimStops = [];

  if (overlayTimeBand && defaultOverlayDataList.length > 0) {
    overlayTimeBand = checkClosestTimeBand(
      overlayTimeBand,
      defaultOverlayDataList
    );

    defaultOverlayDataList = defaultOverlayDataList.filter(
      (timeBandData, index) => {
        return (
          index <=
          defaultOverlayDataList
            .map((data) => data.timeBand)
            .indexOf(overlayTimeBand)
        );
      }
    );
  }

  if (defaultOverlayDataList.length > 0) {
    overlayStops = defaultOverlayDataList.flatMap(
      (timeBand) => timeBand.overlayStops
    );

    if (returnType === "list") {
      overlayStops.forEach((postcodeData) => {
        const postcodeObj = new Postcode(postcodeData.postcode);
        const area =
          listType === SECTOR ? postcodeObj.toSector() : postcodeObj.toOuter();

        // A space needs to be in front if comparing distracts to stop districts like S61 being placed with S6
        const formattedAreaToCompare = listType === OUTER ? `${area} ` : area;
        const postcode = postcodeObj.toPostcode();
        if (postcode.startsWith(formattedAreaToCompare)) {
          const areaIndex = list.findIndex((item) => {
            if (listType === SECTOR) {
              return postcode.startsWith(item.value);
            } else {
              return postcode.startsWith(`${item.value} `);
            }
          });
          if (areaIndex > -1) {
            list[areaIndex].stops++;
            list[areaIndex].stopParcels += postcodeData.stopParcels;
          } else {
            list.push({
              value: area,
              stops: 1,
              stopParcels: postcodeData.stopParcels,
              simStops: 0,
              simStopParcels: 0,
            });
          }
        }
      });
    }

    if (simulatedDataList !== null) {
      if (overlayTimeBand) {
        simulatedDataList = simulatedDataList.filter((timeBandData, index) => {
          return (
            index <=
            simulatedDataList
              .map((data) => data.timeBand)
              .indexOf(overlayTimeBand)
          );
        });
      }

      allSimStops = simulatedDataList.flatMap(
        (timeBand) => timeBand.overlayStops
      );

      if (returnType === "list") {
        allSimStops.forEach((simPostcodeData) => {
          const postcodeObj = new Postcode(simPostcodeData.postcode);
          const area =
            listType === SECTOR
              ? postcodeObj.toSector()
              : postcodeObj.toOuter();
          const formattedAreaToCompare = listType === OUTER ? `${area} ` : area;
          const postcode = postcodeObj.toPostcode();
          if (postcode.startsWith(formattedAreaToCompare)) {
            const areaIndex = list.findIndex((item) => {
              if (listType === SECTOR) {
                return postcode.startsWith(item.value);
              } else {
                return postcode.startsWith(`${item.value} `);
              }
            });
            if (areaIndex > -1) {
              list[areaIndex].simStops++;
              list[areaIndex].simStopParcels += simPostcodeData.stopParcels;
            }
          }
        });
      }
    }

    if (returnType === "list") {
      return list;
    } else if (returnType === "total") {
      return { simTotal: allSimStops.length, total: overlayStops.length };
    } else {
      return "Invalid Return Type";
    }
  }

  return [];
};
