import styled from "@emotion/styled";
import { Grid } from "@mui/material";

import MessageList from "./MessageList.jsx";
import MessageForm from "./MessageForm.jsx";
import aiLoader from "../../../../assets/ailoaderTransparent.gif";

const MessageFormContainer = styled(Grid)(() => ({
  width: "100%",
  paddingTop: 2,
}));

const InputContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  transition: "all 0.5s ease-in-out",
  // paddingBottom: theme.dimensions.chat.input.pBottom,
  // paddingTop: theme.spaces.xxLarge,
  // [theme.breakpoints.down("md")]: {
  //   paddingTop: theme.spaces.large,
  //   paddingBottom: theme.spaces.xxLarge,
  // },
}));

const ImageContainer = styled(Grid)(({ theme }) => ({
  position: "absolute",
  top: `calc(50% - ${theme.dimensions.chat.input.pBottom}px - 60px)`,
}));

const Image = styled("img")({
  width: 120,
  height: 120,
});

const Chat = ({
  messages,
  user,
  onThumbDownClick,
  onThumbUpClick,
  onSendClickChat,
  isTyping,
  isLoading,
  activeTopicId,
  setAnswer,
}) => (
  <>
    {isLoading && (
      <ImageContainer>
        <Image src={aiLoader} alt="Loading" />
      </ImageContainer>
    )}

    <MessageFormContainer>
      <MessageList
        picture={user.picture}
        messages={messages}
        onThumbDownClick={onThumbDownClick}
        onThumbUpClick={onThumbUpClick}
        isTyping={isTyping}
      />
    </MessageFormContainer>
    <InputContainer item container py={2}>
      <MessageForm
        sendMessageToVertex={onSendClickChat}
        isTyping={isTyping}
        activeTopicId={activeTopicId}
        setAnswer={setAnswer}
      />
    </InputContainer>
  </>
);

export default Chat;
