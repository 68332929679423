import styled from "@emotion/styled";
import { ReactComponent as CloudIcon } from "../../assets/widgets/cloudOff.svg";
import { LOADING_WIDGET_ERROR, RETRY } from "../../constants/strings";
import Card from "./ContentCard";

const ErrorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spaces.base,
  flex: 1,
  textAlign: "center",
}));

const ErrorButton = styled(Card.ActionButton)(({ theme }) => ({
  color: theme.colors.brandRed,
  backgroundColor: "unset",
  textTransform: "uppercase",
  cursor: "pointer",
}));

const CardError = ({ onRetry }) => (
  <>
    <ErrorContainer>
      <CloudIcon />
      <Card.Text>{LOADING_WIDGET_ERROR}</Card.Text>
    </ErrorContainer>
    <Card.CardActions>
      <ErrorButton onClick={onRetry}>{RETRY}</ErrorButton>
    </Card.CardActions>
  </>
);

export default CardError;
