import { WidgetsSelectors } from '../widgets';
import { actions as UIActions } from './slice';
import * as UISelectors from './selectors.js';

// todo improvements needed
export const showWidgetInChat = id => async (dispatch, getState) => {
  const homeWidget = WidgetsSelectors.getWidgetById(id)(getState());
  const categoryWidget = UISelectors.getCategoryWidgetById(id)(getState());

  dispatch(UIActions.addChatWidget(homeWidget || categoryWidget));
};

export const setSorting = isSorting => async (dispatch, getState) => {
  const widgets = WidgetsSelectors.getWidgets(getState());

  dispatch(
    UIActions.saveSortingOrder({
      widgets: widgets.map(widget => widget.id),
      isSorting,
    })
  );
};
