import { memo, useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

import InfoMedia from "../../../../components/Media";
import CardContainer from "../../../../components/widgets/core/CardContainer";
import ActionButton from "../../../../components/widgets/ActionButton";
import CardTitle from "../../../../components/widgets/CardTitle";

const Body = styled("div")(({ theme, maxLines }) => ({
  overflow: "hidden",
  color: theme.colors.brandBlack80,
  textOverflow: "ellipsis",
  fontFamily: theme.fonts.plutoRegular,
  cursor: "auto",
  fontSize: 14,
  fontWeight: "400",
  paddingBottom: "3em",
  lineHeight: `${theme.dimensions.card.bodyLineHeight}px`,
  letterSpacing: "0.17px",
  maxHeight: "100%",
  display: "-webkit-box",
  WebkitLineClamp: `${maxLines}`,
  WebkitBoxOrient: "vertical",
}));

const Content = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.dimensions.card.contentGap,
  overflow: "hidden",
}));

const InfoCard = memo(function InfoCard({ data, ...props }) {
  const { media, cardTitle, title, text } = data;
  const bodyRef = useRef();
  const [bodyHeight, setBodyHeight] = useState(0);
  const theme = useTheme();

  const maxLines = useMemo(
    () => Math.floor(bodyHeight / theme.dimensions.card.bodyLineHeight),
    [bodyHeight, theme]
  );
  const link = useMemo(() => data?.links?.document, [data]);

  useLayoutEffect(() => {
    if (bodyRef.current) {
      setBodyHeight(bodyRef.current.offsetHeight);
    }
  }, []);

  return (
    <CardContainer {...props}>
      <Content>
        {media && <InfoMedia data={media} />}
        <CardTitle headerTitle={cardTitle} title={title} />
        <Body ref={bodyRef} maxLines={maxLines}>
          {text}
        </Body>
      </Content>
      {link?.url && <ActionButton link={link} />}
    </CardContainer>
  );
});

export default InfoCard;
