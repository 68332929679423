export const setUpBoundariesWithDepotKeys = (districtBoundaries) => {
  return districtBoundaries.reduce((result, boundaries) => {
    const depotCode = boundaries.depotCode;
    if (!result[depotCode]) {
      result[depotCode] = [];
    }
    result[depotCode].push(boundaries);
    return result;
  }, {});
};
