import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Popup } from "react-mapbox-gl";
import {
  allDepotListSelector,
  pageToShowIndexSelector,
  simulatedVolumeOverlayDataSelector,
} from "../../../../../reducers/app/selector";
import { LocationIcon } from "../../../../../assets/icons/Location";
import { DEPOT, OUTER, SECTOR } from "../../../../../constants/layers";
import { ArrowIcon } from "../../../../../assets/icons/MapArrowIcon";
import "./Layers.css";
import { isMapAdjustingSelector } from "../../../../../reducers/map/selector";
import {
  isMarkerVisible,
  setUpBoundariesWithDepotKeys,
  getCenterOfDepot,
} from "../../../../../utils/map";
import { businessUnitFilterSelector } from "../../../../../reducers/depot/selector";

export const PopupLayer = ({
  map,
  parcelMapData,
  mapLevel,
  districtBoundaries,
  sectorBoundaries,
}) => {
  const [labelMapBounds, setlabelMapBounds] = useState(map.getBounds()); // Need local state to stop rerender of whole map
  const simulatedList = useSelector(simulatedVolumeOverlayDataSelector);
  const isMapAdjusting = useSelector(isMapAdjustingSelector);
  const allDepots = useSelector(allDepotListSelector);
  const pageToShowIndex = useSelector(pageToShowIndexSelector);
  const businessUnitFilter = useSelector(businessUnitFilterSelector);

  const depotBoundaries = setUpBoundariesWithDepotKeys(districtBoundaries);

  useEffect(() => {
    setlabelMapBounds(map.getBounds());
  }, [isMapAdjusting, map]); // Forces map to rerender on drag which updates the markers

  let boundariesList = [];
  let depotParcelData = [];

  if (mapLevel === SECTOR) {
    boundariesList = sectorBoundaries;
  } else if (mapLevel === OUTER || pageToShowIndex === 0) {
    // Stops rendering any depot level code if on Simulation page
    boundariesList = districtBoundaries;
  } else if (mapLevel === DEPOT) {
    boundariesList = allDepots.filter(
      (depot) => depot.businessUnit === businessUnitFilter
    );
    parcelMapData.forEach(({ depotCode, stops }) => {
      const depotIndex = depotParcelData.findIndex((depot) => {
        return depot.value === depotCode;
      });
      const boundaryIndex = boundariesList.findIndex((boundary) => {
        return boundary.depotCode === depotCode;
      });
      if (depotIndex === -1 && boundaryIndex !== -1) {
        depotParcelData.push({ stops, value: depotCode, depotCode });
      } else if (depotIndex > -1 && boundaryIndex !== -1) {
        depotParcelData[depotIndex].stops += stops;
      }
    });
    parcelMapData = depotParcelData;
  }

  let value;
  let popupCenter;
  let parcelIndex;
  return (
    <>
      {parcelMapData.length > 0 && (
        <>
          {boundariesList
            .filter(({ center, depotName, depotCode }) => {
              if (depotName) {
                popupCenter = getCenterOfDepot(depotBoundaries[depotCode]);
              } else {
                popupCenter = center;
              }

              return isMarkerVisible(popupCenter, labelMapBounds);
            })
            .map(({ depotCode, depotName, center, postcode }) => {
              if (depotName) {
                value = depotCode;
                popupCenter = getCenterOfDepot(depotBoundaries[depotCode]);
              } else {
                value = postcode;
                popupCenter = center;
                parcelIndex = parcelMapData.findIndex((mapPostcode) => {
                  return mapPostcode.value === value;
                });
              }

              if (pageToShowIndex === 1) {
                parcelIndex = parcelMapData.findIndex((mapPostcode) => {
                  return (
                    mapPostcode.value === value &&
                    mapPostcode.depotCode === depotCode
                  );
                });
              }

              const coords = [popupCenter[0], popupCenter[1]];
              return (
                map && (
                  <div key={`${value} popup`}>
                    {parcelMapData[parcelIndex] &&
                      parcelMapData[parcelIndex].stops > 0 && (
                        <Popup coordinates={coords} anchor={"bottom"}>
                          {parcelMapData[parcelIndex].stops}
                          {parcelMapData[parcelIndex] &&
                            simulatedList !== null &&
                            pageToShowIndex === 0 && (
                              <div className="popupSimulatedContainer">
                                <ArrowIcon />{" "}
                                {parcelMapData[parcelIndex].simStops}
                              </div>
                            )}
                          <LocationIcon className={"popupIcon"} />
                        </Popup>
                      )}
                  </div>
                )
              );
            })}
        </>
      )}
    </>
  );
};
