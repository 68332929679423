import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { topicsApi } from "../../../api";

export const setLocalTopicAsRead = createAction("topics/setLocalTopicAsRead");

export const fetchTopics = createAsyncThunk("topics/fetchTopics", async () => {
  const { data } = await topicsApi.getTopics();

  return data;
});
