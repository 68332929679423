export const getStripIconSvg = (stroke) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
      <g>
        <path
          d="M-2,2 l4,-4
       M0,20 l20,-20
       M19,21 l20,-20"
          style='stroke:${stroke}; stroke-width:2px; background-color: #000'
        />
      </g>
    </svg>`;
};
