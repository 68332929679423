import React, { useState, useEffect, memo } from "react";
import { Layer } from "react-mapbox-gl";
import { groupBy } from "lodash";
import { BOUNDARIES_ID_BY_LEVEL } from "../../../../../constants/sources";
import { LAYERS, DEPOT, OUTER, SECTOR } from "../../../../../constants/layers";
import { getStripIconSvg } from "../../../../../utils/map/allocation/getStripeIconSvg";
import { isMarkerVisible } from "../../../../../utils/map";
import { useSelector } from "react-redux";
import { isMapAdjustingSelector } from "../../../../../reducers/map/selector";
// Only used in allocation page
// Displays a striped layer on top of the original depot boundary if the district/outer has changed depot from the original allocation
export const MultiDepotBoundary = memo(
  ({ map, mapLevel, districtBoundaries, sectorBoundaries, depotColourMap }) => {
    // Filter out depots that have haven't changed otherwise mapbox would render empty layers

    const [labelMapBounds, setlabelMapBounds] = useState(map.getBounds()); // Need local state to stop rerender of whole map
    const isMapAdjusting = useSelector(isMapAdjustingSelector);

    districtBoundaries = districtBoundaries.filter(
      ({ depotCode, originalDepot, center }) =>
        depotCode !== originalDepot && isMarkerVisible(center, labelMapBounds)
    );
    sectorBoundaries = sectorBoundaries.filter(
      ({ depotCode, originalDepot, center }) =>
        depotCode !== originalDepot && isMarkerVisible(center, labelMapBounds)
    );

    const districtBoundariesByDepot = Object.entries(
      groupBy(districtBoundaries, "originalDepot")
    ).map((test) => [
      test[0],
      test[1].filter(
        ({ depotCode, originalDepot }) => depotCode !== originalDepot
      ),
    ]);

    const sectorBoundariesByDepot = Object.entries(
      groupBy(sectorBoundaries, "originalDepot")
    );

    useEffect(() => {
      setlabelMapBounds(map.getBounds());
    }, [isMapAdjusting, map]); // Forces map to rerender on drag which updates the markers

    // Using a svg string was the only way I could get it to work with the dynamic colours
    // map.addImage only takes a HTML Image or a Bitmap
    const createMapImage = (mapColour, imageName) => {
      const svgstr = getStripIconSvg(mapColour);
      let img = new Image(20, 20);
      img.onload = () => {
        if (!map.hasImage(imageName)) {
          map.addImage(imageName, img);
        }
      };

      const blob = new Blob([svgstr], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);

      img.src = url;
    };

    if (!map.hasImage("fallback-image")) {
      createMapImage("#FFF", "fallback-image");
    }

    const getLayerProps = (level, originalDepot, depotBoundaries) => ({
      filter: [
        "in",
        ["id"],
        ["literal", depotBoundaries.map((boundary) => boundary.id)],
      ],
      id: `${originalDepot}-multi`,
      sourceId: BOUNDARIES_ID_BY_LEVEL[level],
      sourceLayer: LAYERS[level].BOUNDARIES.SOURCE_LAYER,
      type: "fill",
      paint: {
        "fill-pattern": layerPaint(depotBoundaries, originalDepot),
        "fill-opacity": 0.9,
        "fill-outline-color": "transparent",
      },
    });

    const layerPaint = (depotBoundaries, originalDepot) => {
      let depotColourIndex;

      depotColourIndex = depotColourMap.findIndex(
        (depotMap) => depotMap.depot === originalDepot
      );

      const stripeColourExpressionArray = ["match", ["id"]];

      depotBoundaries.forEach(
        ({ id, postcode, depotCode, originalDepot }, index) => {
          const imageName = `multi-${originalDepot}->${depotCode}`;
          const mapColour = depotColourMap[depotColourIndex]?.mapColour
            ? depotColourMap[depotColourIndex]?.mapColour
            : "#FFF";
          stripeColourExpressionArray.push(id);

          createMapImage(mapColour, imageName);

          stripeColourExpressionArray.push(imageName);
        }
      );
      stripeColourExpressionArray.push("fallback-image");

      return stripeColourExpressionArray;
    };

    return (
      map && (
        <>
          {(mapLevel === OUTER || mapLevel === DEPOT) && (
            <>
              {districtBoundariesByDepot.map((depot) => (
                // depot[0] = ORIGINAL DEPOT CODE, depot[1] = BOUNDARIES
                <Layer
                  key={depot[0]}
                  {...getLayerProps(OUTER, depot[0], depot[1])} // Depots do not have features in the mapbox API so the outer level must be used
                />
              ))}
            </>
          )}
          {mapLevel === SECTOR && (
            <>
              {sectorBoundariesByDepot.map((depot) => (
                // depot[0] = ORIGINAL DEPOT CODE, depot[1] = BOUNDARIES
                <Layer
                  key={depot[0]}
                  {...getLayerProps(SECTOR, depot[0], depot[1])}
                />
              ))}
            </>
          )}
        </>
      )
    );
  }
);
