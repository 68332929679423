import styled from '@emotion/styled';

const Iframe = styled('iframe')(() => ({
  height: '100%',
  width: '100%',
  padding: 0,
}));

export const YoutubeEmbed = ({ embedId }) => (
  <Iframe
    src={`https://www.youtube.com/embed/${embedId}`}
    title='YouTube video player'
    frameBorder='0'
    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
    allowFullScreen
  />
);
