import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { AppActions } from "@dpdgroupuk/ai-app";

import { QuantumIcon } from "../../assets/icons/QuantumIcon";
import { LocationIcon } from "../../assets/icons/Location";
import { CalendarIcon } from "../../assets/icons/CalendarIcon";
import { formatDate } from "../../utils/date";
import {
  setVolumeOverlayDate,
  setDefaultOverlayData,
  setShowErrorModal,
  resetDepot,
  handleVolumeSimulatorDepotChange,
} from "../../reducers/app";
import {
  // fullMapOverlayDateSelector,
  // pageToShowIndexSelector,
  volumeOverlayDateSelector,
  userAccessDepotListSelector,
  depotNameListSelector,
} from "../../reducers/app/selector";
// import { firebaseUserSelector } from "../../reducers/user/selector";
import { SubHeader } from "./SubHeader";
import { HeaderDatePicker } from "./HeaderDatePicker";
import "./Header.css";

export const Header = () => {
  const depotNameList = useSelector(depotNameListSelector);
  const [activeDropDown, setActiveDropDown] = useState("none");
  const selectedLocation =
    (depotNameList.length === 1 && depotNameList?.[0]) || "";
  const [locationSearch, setLocationSearch] = useState(selectedLocation);

  // For Vertex AI questions - ensure UI changes properly as locationSearch is a local state
  useEffect(() => {
    setLocationSearch(selectedLocation);
  }, [selectedLocation, setLocationSearch]);

  const { setLoading } = AppActions;

  // const pageToShowIndex = useSelector(pageToShowIndexSelector);
  // const user = useSelector(firebaseUserSelector);
  const userAccessDepotList = useSelector(userAccessDepotListSelector);
  const volumeOverlayDate = useSelector(volumeOverlayDateSelector);
  // const fullMapOverlayDate = useSelector(fullMapOverlayDateSelector);

  const dispatch = useDispatch();

  const LocationDropDown = () => {
    const optionsGenerator = () => {
      const filteredDepots = userAccessDepotList.filter((depot) => {
        const depotName = depot.depotName?.toLowerCase() || "";
        const depotCode = depot.depotCode?.toLowerCase() || "";
        const locationSearchLowerCase = locationSearch.toLowerCase();

        return (
          depotName !== null &&
          depotCode !== null &&
          (locationSearchLowerCase === "" ||
            depotName.includes(locationSearchLowerCase) ||
            depotCode.includes(locationSearchLowerCase) ||
            locationSearchLowerCase.includes(depotName) ||
            locationSearchLowerCase.includes(depotCode))
        );
      });

      if (filteredDepots.length > 0) {
        return filteredDepots.map((depot) => {
          const depotName = depot.depotName || "";
          const depotCode = depot.depotCode || "";
          return (
            <li
              key={depotCode}
              className="headerLocationDropDownValue"
              id={depotCode}
              name={`${depotName} (${depotCode})`}
              onClick={async () => {
                dispatch(setLoading(true));
                // Ignore await message - await IS needed here otherwise loading screen will disappear instantly
                await dispatch(
                  handleVolumeSimulatorDepotChange({
                    depotNameList: [depotName],
                    depotCodeList: [depotCode],
                  })
                );
                dispatch(setLoading(false));
              }}
            >
              {`${depotName} (${depotCode})`}
            </li>
          );
        });
      } else {
        return (
          <li className="headerLocationDropDownValue" id="" name="">
            No Options
          </li>
        );
      }
    };

    return (
      <ul
        className="headerLocationDropDown"
        onClick={() => setActiveDropDown("none")}
      >
        {optionsGenerator()}
      </ul>
    );
  };

  const VolumeOverlay = ({ setDefaultData, setOverlayDate, overlayDate }) => {
    return (
      <div
        className="headerSegment"
        onClick={(e) => {
          e.stopPropagation();
          if (activeDropDown === "volume") {
            setActiveDropDown("none");
          } else {
            dispatch(setShowErrorModal(false));
            setActiveDropDown("volume");
          }
        }}
      >
        <CalendarIcon
          className="headerIcon"
          onClick={() => {
            setActiveDropDown("volume");
          }}
        />
        {/* Volume Overlay: */}
        {overlayDate === null ? (
          <span className="headerOverlayDateInput headerOverlayDateInputPlaceholder">
            Please Select: Date
          </span>
        ) : (
          <div className="headerVolumeDateContainer">
            <div className="headerOverlayDateInput">
              {formatDate(overlayDate, "DD/MM/YYYY")}
            </div>
            <ClearIcon
              className="headerIcon"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setOverlayDate(null));
                dispatch(setDefaultData([]));
                setOverlayDate(null);
                setActiveDropDown("none");
              }}
            />
          </div>
        )}
        {/* <ChevronRightIcon
          className={`headerIcon ${
            activeDropDown === "volume"
              ? "headerChevronIconOpen"
              : "headerChevronIcon"
          }`}
        /> */}
        {activeDropDown === "volume" && (
          <HeaderDatePicker
            activeDropDown={activeDropDown}
            setActiveDropDown={setActiveDropDown}
            setLoading={setLoading}
          />
        )}
      </div>
    );
  };

  return (
    <div className="header" onClick={() => setActiveDropDown("none")}>
      <div className="headerMainSegment">
        <QuantumIcon />
        <SubHeader />
      </div>
      <div className="headerOptions">
        {/* Manual use of Volume Sim only works with 1 depot - for now */}
        {selectedLocation !== "" && depotNameList.length === 1 && (
          <VolumeOverlay
            overlayDate={volumeOverlayDate}
            setDefaultData={setDefaultOverlayData}
            setOverlayDate={setVolumeOverlayDate}
          />
        )}
        <div
          className="headerSegment"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <LocationIcon
            className="headerIcon"
            onClick={() => {
              document.getElementById("headerDepotSearchBox").select();
              setActiveDropDown("location");
            }}
          />
          <input
            className="headerDepotSearchBox"
            id="headerDepotSearchBox"
            value={locationSearch || selectedLocation}
            autoComplete="off"
            onClick={(e) => {
              e.target.select();
              setActiveDropDown("location");
            }}
            onChange={(e) => {
              if (e.target.value === "") {
                dispatch(resetDepot());
              }
              setLocationSearch(e.target.value);
            }}
            placeholder="Please Select: Depot"
          />
          {activeDropDown === "location" && <LocationDropDown />}
        </div>
      </div>
    </div>
  );
};
