export const depotCodeListSelector = (state) => state.vs_app.depotCodeList;
export const depotNameListSelector = (state) => state.vs_app.depotNameList;
export const pageToShowIndexSelector = (state) => state.vs_app.pageToShowIndex;
export const userAccessDepotListSelector = (state) =>
  state.vs_app.userAccessDepotList;
export const activeDatesForDatePickerSelector = (state) =>
  state.vs_app.activeDatesForDatePicker;
export const volumeOverlayDateSelector = (state) =>
  state.vs_app.volumeOverlayDate;
export const simulationPercentageSelector = (state) =>
  state.vs_app.simulationPercentage;
export const simulatedVolumeOverlayDataSelector = (state) =>
  state.vs_app.simulatedVolumeOverlayData;
export const defaultOverlayDataSelector = (state) =>
  state.vs_app.defaultOverlayData;
export const overlayTimeBandSelector = (state) => state.vs_app.overlayTimeBand;
export const allDepotListSelector = (state) => state.vs_app.allDepotList;
export const depotsVisibleOnMapListSelector = (state) =>
  state.vs_app.depotsVisibleOnMapList;
export const depotColourMapSelector = (state) => state.vs_app.depotColourMap;
export const fullMapParcelListSelector = (state) =>
  state.vs_app.fullMapParcelList;
export const fullMapOverlayDateSelector = (state) =>
  state.vs_app.fullMapOverlayDate;
export const activeDatesForSimulationDatePickerSelector = (state) =>
  state.vs_app.activeDatesForSimulationDatePicker;
export const activeDatesForAllocationDatePickerSelector = (state) =>
  state.vs_app.activeDatesForAllocationDatePicker;
export const pageLoadingSelector = (state) => state.vs_app.pageLoading;
export const signInErrorMessageSelector = (state) =>
  state.vs_app.signInErrorMessage;
export const showErrorModalSelector = (state) => state.vs_app.showErrorModal;
export const errorModalMessageSelector = (state) =>
  state.vs_app.errorModalMessage;
