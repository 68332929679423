import { OUTER, SECTOR, POSTCODE } from "../constants/layers";

import { padEnd } from "lodash";

const MAX_LENGTH_SPACE_PADDING = {
  6: 3,
  7: 4,
  8: 5,
};
export class Postcode {
  constructor(postcode = "") {
    postcode = postcode.split("  ").join(" ");
    this.maxLength = 7;
    if (
      postcode.length === 2 ||
      (postcode.length === 4 && postcode.includes(" "))
    ) {
      this.maxLength = 6;
    } else if (postcode.length === 3) {
      this.maxLength = 7;
    } else if (postcode.length === 4 && !postcode.includes(" ")) {
      this.maxLength = 8;
    } else if (postcode.length === 5) {
      this.maxLength = 7;
    } else if (postcode.length >= 6) {
      this.maxLength = postcode.length;
    }

    postcode = padEnd(postcode, MAX_LENGTH_SPACE_PADDING[this.maxLength], " ");
    postcode = padEnd(postcode, this.maxLength, "_");

    if (postcode.split(" ").length === 1) {
      postcode = `${postcode.substr(0, postcode.length - 3)} ${postcode.substr(
        postcode.length - 3
      )}`;
    }
    const secondPartLength = postcode.split(" ")[1].length;
    if (secondPartLength < 3) {
      postcode = padEnd(
        postcode,
        postcode.length + (3 - secondPartLength),
        "_"
      );
    }

    this.postcode = postcode;
    this.outer = this.postcode
      .substring(0, this.postcode.length - 3)
      .split(" ")[0];

    const sector = this.postcode
      .substring(0, this.postcode.length - 2)
      .split(" ")
      .join("");
    this.sector = `${sector.substring(0, sector.length - 1)} ${sector.substring(
      sector.length - 1
    )}`;
  }

  toOuter() {
    return this.outer;
  }

  static toOuter(postcode) {
    const p = new Postcode(postcode);
    return p.toOuter();
  }

  toSector() {
    return this.sector;
  }

  static toSector(postcode) {
    const p = new Postcode(postcode);
    return p.toSector();
  }

  toPostcode() {
    return this.postcode;
  }

  static toPostcode(postcode) {
    const p = new Postcode(postcode);
    return p.toPostcode();
  }

  getPostcode(level) {
    switch (level) {
      case POSTCODE:
        return this.toPostcode();
      case SECTOR:
        return this.toSector();
      case OUTER:
      default:
        return this.toOuter();
    }
  }

  static getPostcode(postcode, level) {
    const p = new Postcode(postcode);
    return p.getPostcode(level);
  }

  getLevel() {
    if (!this.postcode.includes("_")) {
      return POSTCODE;
    } else if (!this.sector.includes("_")) {
      return SECTOR;
    } else {
      return OUTER;
    }
  }

  static getLevel(postcode) {
    const p = new Postcode(postcode);
    return p.getLevel();
  }
}
