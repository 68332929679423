import { createSlice } from "@reduxjs/toolkit";

import {
  fetchMessagesByTopicId,
  sendMessageDashboard,
  sendMessageVolumeSimulator,
} from "../messages/actions.js";
import { moveArrayItemToTopById } from "../../../utils/common.js";

import { fetchTopics } from "./actions";

const topicsSlice = createSlice({
  name: "topics",
  initialState: [],
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchTopics.fulfilled,
      (state, action) => action.payload.topics
    );
    builder.addCase(fetchMessagesByTopicId.pending, (state, action) =>
      state.map((currentTopic) => {
        if (currentTopic.id === action.meta.arg) {
          return { ...currentTopic, unreadCount: 0 };
        }

        return currentTopic;
      })
    );
    builder.addCase(sendMessageDashboard.fulfilled, (state, action) => {
      if (!action.payload.attributes) {
        return state;
      }

      const { topicId, topicName, type } = action.payload.attributes;
      const currentTopic = state.find(({ id }) => id === topicId);

      if (state[0]?.id === topicId) {
        return state;
      }

      if (!currentTopic) {
        return [
          { id: topicId, topicName, type, unreadCount: 0 },
          ...state.slice(0, state.length === 20 ? -1 : undefined),
        ];
      }

      return moveArrayItemToTopById(state, topicId);
    });
    builder.addCase(sendMessageVolumeSimulator.fulfilled, (state, action) => {
      if (!action.payload.attributes) {
        return state;
      }

      const { topicId, topicName, type } = action.payload.attributes;
      const currentTopic = state.find(({ id }) => id === topicId);

      if (state[0]?.id === topicId) {
        return state;
      }

      if (!currentTopic) {
        return [
          { id: topicId, topicName, type, unreadCount: 0 },
          ...state.slice(0, state.length === 20 ? -1 : undefined),
        ];
      }

      return moveArrayItemToTopById(state, topicId);
    });
  },
});

export default topicsSlice;
