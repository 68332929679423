import styled from "@emotion/styled";

const Title = styled("div")(({ theme }) => ({
  fontFamily: theme.fonts.plutoRegular,
  fontSize: 12,
  color: theme.colors.brandRed,
  fontWeight: 400,
  lineHeight: "20px",
  fontStyle: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  letterSpacing: "1px",
  textTransform: "uppercase",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "2",
}));

const HeaderTitle = ({ children, className }) => (
  <Title className={className}>{children}</Title>
);

export default HeaderTitle;
