import {
  POSTCODE_DISTRICT_BOUNDARIES_ID,
  POSTCODE_SECTOR_BOUNDARIES_ID,
  POSTCODE_DISTRICT_BOUNDARIES,
  POSTCODE_SECTOR_BOUNDARIES,
} from "../../../../constants/sources";

import React from "react";
import { Source } from "react-mapbox-gl";

export const Sources = ({ map }) => {
  return (
    map && (
      <>
        <Source
          id={POSTCODE_DISTRICT_BOUNDARIES_ID}
          tileJsonSource={POSTCODE_DISTRICT_BOUNDARIES}
        />
        <Source
          id={POSTCODE_SECTOR_BOUNDARIES_ID}
          tileJsonSource={POSTCODE_SECTOR_BOUNDARIES}
        />
      </>
    )
  );
};
