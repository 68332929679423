import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { palette } from "../../styles/palette";
import { setShowErrorModal } from "../../reducers/app";
import { useDispatch, useSelector } from "react-redux";
import { ErrorIcon } from "../../assets/icons/ErrorIcon";
import { errorModalMessageSelector } from "../../reducers/app/selector";

const useStyles = makeStyles({
  errorContainer: {
    width: "20%",
    minHeight: "10%",
    position: "absolute",
    padding: "0.5rem",
    top: "30%",
    left: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: palette.dpdRed,
    border: `2px solid ${palette.typography.white.solid}`,
    borderRadius: "10px",
    color: palette.typography.white.solid,
    zIndex: "998",
  },
});

export const ErrorModal = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const errorMessage = useSelector(errorModalMessageSelector);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowErrorModal(false));
    }, 6000);
  }, [dispatch]);

  return (
    <Box className={styles.errorContainer}>
      <ErrorIcon />
      <Typography variant={"body1"}>ERROR: {errorMessage}</Typography>
      <Typography variant={"body1"}>Please try again later</Typography>
    </Box>
  );
};
