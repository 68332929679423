import styled from '@emotion/styled';

const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.dimensions.card.contentGap,
  height: '100%',
  overflow: 'hidden',

  '& .text-container-item:last-child': {
    border: 'none',
  },
  paddingTop: theme.spaces.base,
}));

const CardBody = ({ children, className }) => (
  <Body className={className}>{children}</Body>
);

export default CardBody;
