import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slider } from "@mui/material";
import { withStyles } from "@mui/styles";
import RightArrow from "@mui/icons-material/ArrowCircleRight";
import LeftArrow from "@mui/icons-material/ArrowCircleLeft";

import { groupBy } from "lodash";
import { palette } from "../../../styles/palette";
import {
  setSimulationPercentage,
  handleSimulation,
} from "../../../reducers/app";
import {
  defaultOverlayDataSelector,
  simulationPercentageSelector,
  volumeOverlayDateSelector,
  overlayTimeBandSelector,
  depotCodeListSelector,
  simulatedVolumeOverlayDataSelector,
} from "../../../reducers/app/selector";
import { setUpSimulationParcelList } from "../../../utils/map";
import { ArrowIcon } from "../../../assets/icons/SidebarArrowIcon";
import { formatDate } from "../../../utils/date";
import { SLIDER_VALUES } from "../../../constants/sliderValues";
import { OUTER } from "../../../constants/layers";
import "../Sidebar.css";
import { districtBoundariesSelector } from "../../../reducers/depot/selector";

const StyledSlider = withStyles(() => ({
  root: {
    width: "80%",
  },
  thumb: {
    color: palette.dpdRed,
  },
  track: {
    color: palette.dpdRed,
  },
  rail: {
    color: palette.typography.black.solid,
  },
  mark: {
    color: palette.dpdRed,
  },
  markLabel: {
    color: palette.lightGrey,
  },
  markLabelActive: {
    color: palette.lightGrey,
  },
}))((props) => <Slider {...props} />);

export const SimulationSideBar = ({ setLoading }) => {
  const [errorMsg, setErrorMsg] = useState("");

  const percentageValue = useSelector(simulationPercentageSelector);
  const depotCodeList = useSelector(depotCodeListSelector);
  const [depotCodeToShowIndex, setDepotCodeToShowIndex] = useState(0);
  const volumeOverlayDate = useSelector(volumeOverlayDateSelector);
  const simulatedDataList = useSelector(simulatedVolumeOverlayDataSelector);
  const defaultOverlayDataList = useSelector(defaultOverlayDataSelector);
  const overlayTimeBand = useSelector(overlayTimeBandSelector);
  const districtBoundaries = useSelector(districtBoundariesSelector);

  const districtBoundariesByDepot = groupBy(districtBoundaries, "depotCode");

  const depotCodeToShow = depotCodeList[depotCodeToShowIndex];

  useEffect(() => {
    if (
      defaultOverlayDataList.length < 1 &&
      depotCodeList.length < 1 &&
      volumeOverlayDate
    ) {
      setErrorMsg(
        `No Data Found for Date: ${volumeOverlayDate} and Depots: ${depotCodeList.toString()}`
      );
    }
  }, [defaultOverlayDataList, depotCodeList, volumeOverlayDate]);

  const dispatch = useDispatch();

  const DistrictsList = () => {
    let districts = setUpSimulationParcelList(
      OUTER,
      defaultOverlayDataList,
      simulatedDataList,
      overlayTimeBand,
      "list"
    );

    if (
      Object.values(districtBoundariesByDepot).length > 1 &&
      districtBoundariesByDepot?.[0]
    ) {
      districts = districts.filter(({ value }) =>
        districtBoundariesByDepot[depotCodeToShow]
          .map(({ postcode }) => postcode)
          .includes(value)
      );
    }

    return districts
      .sort((current, next) => next.stops - current.stops)
      .map((postcode, pIndex) => {
        return (
          <div
            key={pIndex}
            className="simulatedDataListRow"
            style={{
              gridTemplateColumns: `${
                simulatedDataList !== null && simulatedDataList.percentage !== 0
                  ? "repeat(4,1fr)"
                  : "repeat(2,1fr)"
              }`,
            }}
          >
            <div>{postcode.value}:</div>
            <div>{postcode.stops}</div>
            {simulatedDataList !== null &&
              simulatedDataList.percentage !== 0 && (
                <>
                  <ArrowIcon />
                  <div>{postcode.simStops}</div>
                </>
              )}
          </div>
        );
      });
  };

  const sliderMarks = SLIDER_VALUES.MARKS;
  let currentValue = sliderMarks[0];
  const sliderLength =
    (sliderMarks[sliderMarks.length - 1] - sliderMarks[0]) / 10;
  const sliderValues = [];

  for (let index = 0; index <= sliderLength; index++) {
    sliderValues.push({
      value: index,
      label: sliderMarks.includes(currentValue) ? `${currentValue}%` : null,
      popup: `${currentValue}%`,
      percentage: currentValue / 100,
    });
    currentValue = currentValue + 10;
  }

  // Always ensures zero is the default value regardless of the array (i.e, has the negative values)
  const getIndexOfZero = sliderValues.findIndex(
    (value) => value.percentage === 0
  );

  return (
    <>
      <div className="simulationContainer">
        {depotCodeList?.length > 0 &&
        volumeOverlayDate &&
        defaultOverlayDataList.length > 0 ? (
          <>
            <p>Select value(%) to simulate:</p>
            <StyledSlider
              key={`${volumeOverlayDate}-sidebar-slider`}
              marks={sliderValues}
              max={sliderValues.length - 1}
              step={1}
              defaultValue={getIndexOfZero}
              valueLabelDisplay={"auto"}
              onChangeCommitted={(e, value) => {
                dispatch(
                  setSimulationPercentage(sliderValues[value].percentage)
                );
              }}
              valueLabelFormat={(value) => {
                return sliderValues[value].popup;
              }}
            />
            <button
              className="sideBarButton"
              onClick={async () => {
                dispatch(setLoading(true));
                // Ignore await message - await IS needed here otherwise loading screen will disappear instantly
                await dispatch(
                  handleSimulation({
                    percentage: percentageValue,
                    depotCodeList,
                    date: volumeOverlayDate,
                  })
                );
                dispatch(setLoading(false));
              }}
            >
              {percentageValue === 0
                ? "Show original values"
                : `Simulate ${Math.abs(percentageValue * 100)}% ${
                    percentageValue > 0 ? "increase" : "decrease"
                  }`}
            </button>
          </>
        ) : (
          <div className="sideBarSimulateButtonAltText">
            Please select a depot and date first
          </div>
        )}
      </div>
      <div>
        {defaultOverlayDataList.length > 0 ? (
          <div className="simulatedDataListContainer">
            <div className="simulatedDataListText">
              {formatDate(volumeOverlayDate, "MMMM Do YYYY")}
            </div>
            <div className="simulatedDataDepotSelectorContainer">
              {depotCodeToShowIndex !== 0 && (
                <LeftArrow
                  className="arrowButton"
                  onClick={() => {
                    setDepotCodeToShowIndex(depotCodeToShowIndex - 1);
                  }}
                />
              )}
              <div className="simulatedDataListText">{depotCodeToShow}</div>
              {depotCodeToShowIndex !== depotCodeList.length - 1 &&
                depotCodeList.length > 1 && (
                  <RightArrow
                    className="arrowButton"
                    onClick={() => {
                      setDepotCodeToShowIndex(depotCodeToShowIndex + 1);
                    }}
                  />
                )}
            </div>

            <div className="simulationSimulatedDataContainer">
              Original stops
              {simulatedDataList !== null &&
                simulatedDataList.percentage !== 0 && (
                  <>
                    <ArrowIcon />
                    {simulatedDataList.percentage * 100}%
                  </>
                )}
            </div>
            <div className="simulatedDataList">
              <DistrictsList />
            </div>
          </div>
        ) : (
          <div className="simulationErrorMsg">{errorMsg}</div>
        )}
      </div>
    </>
  );
};
