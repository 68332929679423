import styled from '@emotion/styled';

const Text = styled('div')(({ theme }) => ({
  fontFamily: theme.fonts.plutoLight,
  fontSize: 14,
  color: theme.colors.brandBlack,
  fontWeight: 370,
  lineHeight: '17px',
}));

const CardText = ({ children, className }) => (
  <Text className={className}>{children}</Text>
);

export default CardText;
