import { useEffect, useMemo, useRef, useState } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  FormHelperText,
} from "@mui/material";
import styled from "@emotion/styled";

import { ReactComponent as SendIcon } from "../../../../assets/sendFilled.svg";
import { trimString } from "../../../../utils/string";
import { MAX_CHARS_COUNT, MIN_CHARS_COUNT } from "../../../../constants";

const StyledInput = styled(InputBase)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.radius.medium,
  border: `1px solid ${theme.colors.warmGrey80}`,
  backgroundColor: theme.colors.brandWhite,
  fontSize: 16,
  lineHeight: "26px",
  padding: "0 12px",
  height: theme.dimensions.chat.input.height,
  "& .MuiInputBase-input": {
    padding: 0,
    height: "auto",
    color: theme.colors.brandBlack,
    "&::placeholder": {
      opacity: 0.5,
    },
  },
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  position: "absolute",
  top: theme.dimensions.chat.input.height,
  right: 0,
  color: theme.colors.black20,
}));

const MessageForm = ({ sendMessageToVertex, isTyping, activeTopicId }) => {
  const [message, setMessage] = useState("");
  const inputRef = useRef();

  const isSendDisabled = useMemo(() => {
    const string = trimString(message);

    return !string || string.length < MIN_CHARS_COUNT || isTyping;
  }, [message, isTyping]);

  useEffect(() => {
    setMessage("");
    setTimeout(() => inputRef.current?.focus(), 100);
  }, [activeTopicId]);

  return (
    <Grid container justifyContent="space-between" flexWrap="nowrap">
      <Grid item width="100%">
        <FormControl variant="standard" fullWidth>
          <StyledInput
            inputRef={inputRef}
            inputProps={{ maxLength: MAX_CHARS_COUNT }}
            value={message}
            autoFocus
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) =>
              e.keyCode === 13 &&
              !isSendDisabled &&
              sendMessageToVertex(message, setMessage)
            }
            placeholder="Ask me anything..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    "&.Mui-disabled": {
                      pointerEvents: "all",
                      cursor: "pointer",
                      "& > svg": {
                        opacity: 0.3,
                      },
                    },
                  }}
                  onClick={() => {
                    sendMessageToVertex(message, setMessage);
                  }}
                  disabled={isSendDisabled}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <HelperText>{`${message.length} / ${MAX_CHARS_COUNT}`}</HelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default MessageForm;
