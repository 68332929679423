import axios from "axios";

const { REACT_APP_MAIN_PROJECT_API } = process.env;

export const api = axios.create({
  baseURL: REACT_APP_MAIN_PROJECT_API,
  withCredentials: true,
  transformResponse: axios.defaults.transformResponse.concat(
    (params) => params.data || params
  ),
});

export const createResponseInterceptor = (onFulfilled, onRejected) => {
  api.interceptors.response.use(onFulfilled, onRejected);
};
