export const OPERATIONS_PRODUCTS = [
  {
    title: "Volume Simulator",
    link: "/volumeSimulator",
  },
  {
    title: "Plan Management",
    link: "/",
  },
  {
    title: "Driver Management",
    link: "/",
  },
  {
    title: "Section Management",
    link: "/",
  },
  {
    title: "Depot Management",
    link: "/",
  },
  {
    title: "Route Assignment",
    link: "/",
  },
];
