import axios from "axios";
import { getAuthToken } from "./firebase";

axios.defaults.baseURL = process.env.REACT_APP_VOLUME_SIM_API;
axios.defaults.withCredentials = true;

export const authenticateUser = async () =>
  await axios.get(`/auth`, {
    headers: { authorization: await getAuthToken() },
  });

export const getUserData = async (userId) =>
  await axios.get(`/users/${userId}`, {
    headers: { authorization: await getAuthToken() },
  });

export const simulateData = async (
  depotCodeList,
  volumeDate,
  percentage,
  districtAndSectorList = []
) =>
  await axios.post(
    `/simulate`,
    {
      depotCodeList, //optional if districtAndSectorList present
      volumeDate,
      percentage,
      districtAndSectorList, // optional
    },
    { headers: { authorization: await getAuthToken() } }
  );

export const getBoundariesAndActiveOverlayDates = async (depotCodeList) =>
  await axios.get(`/postcode/boundaries`, {
    params: { depotCodeList },
    headers: { authorization: await getAuthToken() },
  });

export const getAllDepotsWithMapboxBoundaries = async () =>
  await axios.get(`/postcode/depots`, {
    headers: { authorization: await getAuthToken() },
  });

export const getParcelDataForFullMap = async (overlayDate) =>
  await axios.get(`/postcode/${overlayDate}/depots`, {
    headers: { authorization: await getAuthToken() },
  });
