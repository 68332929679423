import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { AppActions } from "@dpdgroupuk/ai-app";

import { getItemMaxHeight } from "../../utils/charts.js";
import { trimString } from "../../utils/string.js";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { Mapper } from "./components/Mapper";
import { ErrorModal } from "./components/ErrorModal/index.js";
import Chat from "../../pages/VolumeSimulator/components/Chat/Chat.jsx";

import { getFormattedMessages } from "../../pages/models/messages";
import { MessagesSelectors, MessagesActions, UserSelectors } from "../../store";
import useModal from "../../pages/hooks/useModal";
import { useChatActions } from "../../pages/hooks/useChatActions";

import { getUserData } from "./utils/api.js";
import {
  setuserAccessDepotList,
  resetAppState,
  handleVolumeSimulatorDepotChange,
  handleVolumeSimulatorDateChange,
  handleSimulation,
  setShowErrorModal,
  setErrorModalMessage,
} from "./reducers/app";
import {
  showErrorModalSelector,
  userAccessDepotListSelector,
} from "./reducers/app/selector.js";

const Grid = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(4, 1fr)`,
  // rowGap: theme.spaces.xxLarge,
  columnGap: theme.dimensions.home.columnGap,
}));

const GridItem = styled("div")(({ colEnd = 1, rowEnd = 1 }) => ({
  height: getItemMaxHeight(rowEnd),
  width: "100%",
  margin: "0 auto",
  gridColumnEnd: `span ${colEnd}`,
  gridRowEnd: `span ${rowEnd}`,
}));

const ChatPageContainer = styled(Grid)(({ theme, isChatActive }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  alignItems: "stretch",
  [theme.breakpoints.down("md")]: {
    width: "200vw",
    paddingBottom: `${theme.dimensions.chat.switchButtonsHeight}px`,
    transition: "0.5s ease",
    transform: isChatActive ? "translateX(0)" : "translateX(-50%)",
  },
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  wordBreak: "break-word",
  alignItems: "center",
  // margin: `0 ${theme.spaces.xLarge}px`,
  [theme.breakpoints.down("md")]: {
    margin: `0 ${theme.spaces.xxLarge}px`,
  },
}));

export const VolumeSimulatorPage = ({ spannerUserId }) => {
  const dispatch = useDispatch();

  const { setLoading } = AppActions;

  const showErrorModal = useSelector(showErrorModalSelector);
  const [isChatActive, setIsChatActive] = useState(true);
  const [onSendMessage, isTyping, isLoading, activeTopicId] = useChatActions();
  const user = useSelector(UserSelectors.getUser);
  const messages = useSelector(MessagesSelectors.getMessages);
  const depotList = useSelector(userAccessDepotListSelector);

  useEffect(() => {
    dispatch(resetAppState());
    const findUser = async () => {
      try {
        dispatch(setLoading(true));
        const user = await getUserData(spannerUserId);

        dispatch(setuserAccessDepotList(user.data.data.user.depotAccess));
        dispatch(setLoading(false));
      } catch (e) {
        dispatch(setShowErrorModal(true));
        dispatch(setErrorModalMessage("Getting User Depot Data"));
        dispatch(setLoading(false));
      }
    };

    findUser();
  }, [dispatch, setLoading, spannerUserId]);

  const preparedMessages = useMemo(
    () => getFormattedMessages(messages),
    [messages]
  );

  const onSubmitFeedback = useCallback(
    async (messageId, feedbackComment) => {
      const { payload } = dispatch();
      MessagesActions.dislikeMessage({
        messageId,
        feedbackComment,
      });

      if (payload) {
        dispatch(MessagesActions.dislikeLocalMessage(messageId));
      }
    },
    [dispatch]
  );

  const { onModalOpen } = useModal({
    onSubmit: onSubmitFeedback,
  });

  const onThumbUpClick = useCallback(
    async (messageId) => {
      const { payload } = await dispatch();
      MessagesActions.likeMessage(messageId);

      if (payload) {
        dispatch(MessagesActions.likeLocalMessage(messageId));
      }
    },
    [dispatch]
  );

  const sendMessageToVertex = async (message, setMessage) => {
    const filteredDepots = await depotList.map(({ depotCode, depotName }) => ({
      depotCode,
      depotName,
    }));
    const res = await onSendMessage(trimString(message), filteredDepots);
    const {
      depotCodeList = [],
      depotNameList = [],
      date = null,
      percentage = 0,
      districtAndSectorList = [],
    } = await res.messages[0];
    setMessage("");
    // Stops requests going through if there's no depot code -> Exception for simulating certain sectors and districts without mentioning depotCode
    if (depotCodeList?.length < 1 && districtAndSectorList?.length < 1) return;
    dispatch(setLoading(true));
    try {
      await dispatch(
        handleVolumeSimulatorDepotChange({
          depotCodeList,
          depotNameList,
          districtAndSectorList,
        })
      );
      if (date) {
        await dispatch(
          handleVolumeSimulatorDateChange({
            date,
            depotCodeList,
            districtAndSectorList,
          })
        );
      }
      if (percentage) {
        await dispatch(
          handleSimulation({
            percentage: percentage / 100,
            depotCodeList,
            date,
            districtAndSectorList,
          })
        );
      }
      dispatch(setLoading(false));
    } catch (e) {
      // Each function already has its own error handling - this is here to stop them running if one of the functions before fails
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      {showErrorModal && <ErrorModal />}
      <Grid>
        <GridItem colEnd={2} rowEnd={2}>
          <Mapper />
        </GridItem>
        <GridItem colEnd={2} rowEnd={1}>
          <ChatPageContainer isChatActive={isChatActive}>
            <ContentWrapper>
              <Chat
                messages={preparedMessages}
                user={user}
                onThumbDownClick={onModalOpen}
                onThumbUpClick={onThumbUpClick}
                onSendClickChat={sendMessageToVertex}
                isTyping={isTyping}
                isLoading={isLoading}
                activeTopicId={activeTopicId}
              />
            </ContentWrapper>
          </ChatPageContainer>
        </GridItem>
        <GridItem>
          <Header />
        </GridItem>
        <GridItem>
          <Sidebar />
        </GridItem>
      </Grid>
    </>
  );
};
