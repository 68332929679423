import { messagesApi, topicsApi } from "../../../api";
import { createHandledAsyncThunk, createLoadingAsyncThunk } from "../utils";
import { v4 as uuidv4 } from "uuid";

export const fetchMessagesByTopicId = createHandledAsyncThunk(
  "messages/fetchMessagesByTopicId",
  async (topicId) => {
    const { data } = await topicsApi.getTopicMessages(topicId);

    return data;
  }
);

export const likeMessage = createLoadingAsyncThunk(
  "messages/likeMessage",
  async (messageId) => {
    const { data } = await messagesApi.likeMessage(messageId);

    return data;
  }
);

export const dislikeMessage = createLoadingAsyncThunk(
  "messages/dislikeMessage",
  async ({ messageId, feedbackComment }) => {
    const { data } = await messagesApi.dislikeMessage(messageId, {
      feedbackComment,
    });

    return data;
  }
);

// export const sendMessage = createHandledAsyncThunk(
//   "messages/sendMessage",
//   async (
//     { question, topicId, route = "", parcelNumber },
//     { getState, signal }
//   ) => {
//     const state = getState();
//     let body = { question, parcelNumber };
//     let queryParams = {};

//     if (topicId) {
//       const topic = TopicsSelectors.getTopicById(state, topicId);

//       body = {
//         ...body,
//         topicId,
//         topicName: topic.topicName,
//         type: topic.type,
//       };
//     }

//     if (route) {
//       queryParams = {
//         route,
//       };
//     }

//     const { data } = await messagesApi.sendMessage(body, queryParams, signal);

//     return data;
//   }
// );

export const sendMessageDashboard = createHandledAsyncThunk(
  "messages/sendMessageDashboard",
  async ({ question, topicId, userId }) => {
    const res = await messagesApi.sendMessageDashboard({
      message: question,
    });
    const { results, responseAI, naturalLanguageResponse, query } = res;
    const data = {
      attributes: {
        topicId,
        topicName: question,
      },
      cardData: null,
      messages: [
        {
          id: `${userId}+${uuidv4()}`,
          question: question,
          answer: naturalLanguageResponse.response,
          results,
          responseAI,
          query,
        },
      ],
    };
    return data;
  }
);

export const sendMessageVolumeSimulator = createHandledAsyncThunk(
  "messages/sendMessageVolumeSimulator",
  async ({ question, depots, topicId, userId }) => {
    const res = await messagesApi.sendMessageVolumeSimulator({
      message: question,
      depots,
    });
    const {
      message,
      depotName,
      depotCode,
      depotCodeList,
      depotNameList,
      date,
      percentage,
      districtAndSectorList,
    } = res;

    const data = {
      attributes: {
        topicId,
        topicName: question,
      },
      cardData: null,
      messages: [
        {
          id: `${userId}+${uuidv4()}`,
          question: question,
          answer: message,
          depotName,
          depotCode,
          depotCodeList,
          depotNameList,
          date,
          percentage,
          districtAndSectorList,
        },
      ],
    };

    return data;
  }
);
