import { memo, useCallback, useMemo } from "react";
import Card from "../../../components/widgets/ContentCard";
import { ReactComponent as PinFilled } from "../../../assets/widgets/pinFilled.svg";
import { ReactComponent as PinUnfilled } from "../../../assets/widgets/pinUnfilled.svg";
import { ReactComponent as InfoIcon } from "../../../assets/widgets/info.svg";
import { ReactComponent as MessageBubbleIcon } from "../../../assets/widgets/messageBubble.svg";
import CardErrorComponent from "../../../components/widgets/CardError";

const CardContainer = memo(function CardContainer({
  cardId,
  onFlip,
  flipIcon: FlipIcon,
  onPinClick,
  onUnpinClick,
  pinned = true,
  unpinDisabled = false,
  onOpenWidgetInChatClick,
  topicId,
  onRetry,
  isLoadingError,
  isChatBtnVisible,
  className,
  children,
}) {
  const onUnpin = useCallback(() => {
    onUnpinClick(cardId);
  }, [cardId, onUnpinClick]);

  const onPin = useCallback(() => {
    onPinClick && onPinClick(cardId);
  }, [cardId, onPinClick]);

  const onOpenWidgetInChat = useCallback(() => {
    onOpenWidgetInChatClick && onOpenWidgetInChatClick(cardId, topicId);
  }, [cardId, topicId, onOpenWidgetInChatClick]);

  const onRetryClick = useCallback(() => {
    onRetry(cardId);
  }, [cardId, onRetry]);

  const FlipActionIcon = useMemo(
    () => (FlipIcon ? <FlipIcon /> : <InfoIcon />),
    [FlipIcon]
  );

  return (
    <Card className={className}>
      <Card.Header>
        <Card.HeaderActions>
          {!isLoadingError && isChatBtnVisible && onOpenWidgetInChatClick && (
            <Card.HeaderButton onClick={onOpenWidgetInChat}>
              <MessageBubbleIcon />
            </Card.HeaderButton>
          )}
          {(onPinClick || onUnpinClick) && (
            <Card.HeaderButton disabled={pinned && unpinDisabled}>
              {pinned ? (
                <PinFilled onClick={onUnpin} />
              ) : (
                <PinUnfilled onClick={onPin} />
              )}
            </Card.HeaderButton>
          )}
          {onFlip && (
            <Card.HeaderButton onClick={onFlip}>
              {FlipActionIcon}
            </Card.HeaderButton>
          )}
        </Card.HeaderActions>
      </Card.Header>
      <Card.Body>
        {isLoadingError ? (
          <CardErrorComponent onRetry={onRetryClick} />
        ) : (
          children
        )}
      </Card.Body>
    </Card>
  );
});

export default CardContainer;
