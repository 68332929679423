import styled from "@emotion/styled";
import FilledButton from "./FilledButton";

const OutlinedButton = styled(FilledButton)(({ theme }) => ({
  backgroundColor: theme.colors.brandWhite,
  border: `1px solid ${theme.colors.brandRed}`,
  padding: "12px 26px",
  color: theme.colors.brandRed,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export default OutlinedButton;
