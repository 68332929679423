import debounce from 'lodash/debounce';
import { enqueueSnackbar } from 'notistack';
import { palette } from '../theme/theme.enums';

export const showErrorSnackbar = (errorMessage, options) =>
  enqueueSnackbar(errorMessage, {
    variant: 'error',
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    hideIconVariant: true,
    classes: { root: { backgroundColor: palette.brandRed } },
    autoHideDuration: 4000,
    disableWindowBlurListener: true,
    ...options,
  });

export const debouncedShowErrorSnackbar = debounce(showErrorSnackbar, 100);
