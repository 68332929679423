import { DEPOT, OUTER, SECTOR } from "../../../constants/layers";
import { Postcode } from "../../postcode";

export const setUpAllocationParcelList = (
  fullMapParcelList,
  mapLevel = OUTER
) => {
  let districts = [];
  let sectors = [];

  if (mapLevel === DEPOT) mapLevel = OUTER; // Depots do not have features in the mapbox API so the outer level must be used

  if (fullMapParcelList.length > 0) {
    fullMapParcelList.forEach((parcelData) => {
      const postcode = new Postcode(parcelData.sector);
      const formattedSector = postcode.toSector();
      const formattedDistrict = postcode.toOuter();
      const parcelDepotCode = parcelData.depotCode.padStart(4, "0");

      // Postcode AND Depot needed to differentiate between DPD and Local depots
      const districtIndex = districts.findIndex(
        (district) =>
          district.depotCode === parcelDepotCode &&
          district.value === formattedDistrict
      );
      const sectorIndex = sectors.findIndex(
        (sector) =>
          sector.depotCode === parcelDepotCode &&
          sector.value === formattedDistrict
      );

      if (districtIndex === -1) {
        districts.push({
          value: formattedDistrict,
          stops: parcelData.totalStops,
          stopParcels: parcelData.totalParcels,
          depotCode: parcelDepotCode,
        });
      } else {
        districts[districtIndex].stops += parcelData.totalStops;
        districts[districtIndex].stopParcels += parcelData.totalParcels;
      }

      if (sectorIndex === -1) {
        sectors.push({
          value: formattedSector,
          stops: parcelData.totalStops,
          stopParcels: parcelData.totalParcels,
          depotCode: parcelDepotCode,
        });
      } else {
        sectors[sectorIndex].stops += parcelData.totalStops;
        sectors[sectorIndex].stopParcels += parcelData.totalParcels;
      }
    });
  }

  if (mapLevel === OUTER) {
    return districts;
  } else if (mapLevel === SECTOR) {
    return sectors;
  } else {
    return [];
  }
};
