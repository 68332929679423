import Container from './Container.jsx';
import Body from './Body.jsx';
import Header from './CardHeader.jsx';
import HeaderTitle from './HeaderTitle.jsx';
import Title from './Title.jsx';
import Text from './Text.jsx';
import HeaderActions from './HeaderActions.jsx';
import CardActions from './CardActions.jsx';
import HeaderTitles from './HeaderTitles.jsx';
import HeaderActionButton from './HeaderActionButton.jsx';
import CardActionButton from './CardActionButton.jsx';

//TODO rename to Card
const Card = Container;

Card.Body = Body;
Card.Title = Title;
Card.Header = Header;
Card.HeaderTitle = HeaderTitle;
Card.HeaderActions = HeaderActions;
Card.Text = Text;
Card.CardActions = CardActions;
Card.HeaderTitles = HeaderTitles;
Card.HeaderButton = HeaderActionButton;
Card.ActionButton = CardActionButton;

export default Card;
