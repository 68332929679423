// TODO: this is temp file
import { keyBy } from "lodash";

import { SUB_NAV_ROUTES } from "./navigation";

import background from "../assets/widgets/navPlaceholder.png";
import background2 from "../assets/widgets/navPlaceholder2.png";
import background3 from "../assets/widgets/navPlaceholder3.png";
import background4 from "../assets/widgets/navPlaceholder4.png";

import { EMPTY_TOPIC_ID } from "./index";
import { OPEN_DASHBOARD, OPEN_FOLDER } from "./strings";

export const TEST_CARDS = [
  {
    id: "e47c5e59-3d66-4b2d-9a5c-b3c317ae3029",
    topicId: EMPTY_TOPIC_ID,
    type: "NEWS",
    question: "APPLICATION",
    data: {
      headerTitle: "DPD News",
      title: "Wellbeing Wednesday",
      text: "A description about this section which tells the user what type of information can be found in here",
      navigationPath: "news/dpd", // TODO
      media: {
        type: "image",
        url: background,
      },
    },
  },
  {
    id: "68168466-6287-4666-9029-f1a02079beb8",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_PT",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "P&T Documents",
      text: "Access, search and stay updated with the latest P&T business documents and policies.",
      navigationPath: "docs/pt-policy", // TODO
      buttonTitle: OPEN_FOLDER,
      media: {
        type: "image",
        url: background,
      },
    },
  },
  {
    id: "e27d74e3-86da-4a2f-8c39-ab21a0e82756",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_IT",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "IT Documents",
      text: "Access, search and stay updated with the latest IT business documents and policies.",
      navigationPath: "docs/it-policy", // TODO
      buttonTitle: OPEN_FOLDER,
      media: {
        type: "image",
        url: background2,
      },
    },
  },

  {
    id: "e8c74b57-eeb7-441a-95cf-24ffb6cfba3c",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_LEGAL",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "Legal Documents",
      text: "Access, search and stay updated with the latest legal business documents and policies.",
      navigationPath: null,
      buttonTitle: OPEN_FOLDER,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
];

const NEWS_CARDS = [
  {
    id: "e47c5e59-3d66-4b2d-9a5c-b3c317ae3029",
    topicId: EMPTY_TOPIC_ID,
    type: "NEWS",
    question: "APPLICATION",
    data: {
      headerTitle: "DPD News",
      title: "Wellbeing Wednesday",
      text: "A description about this section which tells the user what type of information can be found in here",
      navigationPath: "news/dpd", // TODO
      media: {
        type: "image",
        url: background,
      },
    },
  },
];

export const DOCS_CARDS = [
  {
    id: "68168466-6287-4666-9029-f1a02079beb8",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_PT",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "P&T Documents",
      text: "Access, search and stay updated with the latest P&T business documents and policies.",
      navigationPath: "docs/pt-policy", // TODO
      buttonTitle: OPEN_FOLDER,
      media: {
        type: "image",
        url: background,
      },
    },
  },
  {
    id: "e27d74e3-86da-4a2f-8c39-ab21a0e82756",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_IT",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "IT Documents",
      text: "Access, search and stay updated with the latest IT business documents and policies.",
      navigationPath: "docs/it-policy", // TODO
      buttonTitle: OPEN_FOLDER,
      media: {
        type: "image",
        url: background2,
      },
    },
  },

  {
    id: "e8c74b57-eeb7-441a-95cf-24ffb6cfba3c",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_LEGAL",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "Legal Documents",
      text: "Access, search and stay updated with the latest legal business documents and policies.",
      navigationPath: null,
      buttonTitle: OPEN_FOLDER,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "ebb98297-bf34-411e-9dc0-f01f166aacb1",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_HS",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "H&S Documents",
      text: "Access, search and stay updated with the latest H&S documents and policies.",
      navigationPath: null,
      buttonTitle: OPEN_FOLDER,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "409238d2-b7f9-4237-9cd3-c6446ee2d624",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_SALES_AND_CRM",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "Sales & CRM Documents",
      text: "Access, search and stay updated with the latest sales & CRM documents and policies.",
      navigationPath: null,
      buttonTitle: OPEN_FOLDER,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "36f49ade-5542-46e6-bee8-489dfd31dfdc",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_FLEET_AND_TRANSPORT",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "Fleet & Transport Documents",
      text: "Access, search and stay updated with the latest fleet & transport documents and policies.",
      navigationPath: null,
      buttonTitle: OPEN_FOLDER,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "1fe999e6-1dd7-4895-8578-81bb8153d162",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_INTERNATIONAL",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "International Documents",
      text: "Access, search and stay updated with the latest international business documents and policies.",
      navigationPath: null,
      buttonTitle: OPEN_FOLDER,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "17a6cc86-4499-4daf-8c58-ea1f49db04ad",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_DOCS_FINANCIAL",
    question: "APPLICATION",
    data: {
      headerTitle: "Business documents",
      title: "Financial Documents",
      text: "Access, search and stay updated with the latest financial business documents and policies.",
      navigationPath: null,
      buttonTitle: OPEN_FOLDER,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  // {
  //   id: '4f03be07-44a8-4233-94a8-92e97541a058',
  //   topicId: EMPTY_TOPIC_ID,
  //   type: 'APP_DOCS_ALL_DOCS',
  //   question: 'APPLICATION',
  //   data: {
  //     headerTitle: 'Business documents',
  //     title: 'List all Documents',
  //     text: 'View the full list of business documents and policies here.',
  //     navigationPath:
  //       'https://drive.google.com/file/d/1psARVRDztLdSx087GUKrE-GwqLOYu5qW/view?pli=1',
  //     externalLink: true,
  //     media: {
  //       type: 'image',
  //       url: background3,
  //     },
  //   },
  // },
  // {
  //   id: 'edf449f5-5cbe-448e-ac6f-a42e7717d9be',
  //   topicId: EMPTY_TOPIC_ID,
  //   type: 'APP_DOCS_HELPER',
  //   question: 'APPLICATION',
  //   data: {
  //     headerTitle: 'Business documents',
  //     title: 'Document Helper Guide',
  //     text: 'Take a look at some example questions you can ask to help make the most of your personal assistant.',
  //     navigationPath:
  //       'https://drive.google.com/file/d/14-AfSyuXGtHsNXIqHixa3IwTOv_6PYyw/view',
  //     externalLink: true,
  //     media: {
  //       type: 'image',
  //       url: background,
  //     },
  //   },
  // },
];

export const ANALYTICS_CARDS = [
  {
    id: "f839d6ec-d1d3-47e1-b26c-5cc3e87eec88",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_ANALYTICS_CRM",
    question: "APPLICATION",
    data: {
      headerTitle: "Analytics",
      title: "CRM Volume & Revenue",
      text: "Stay up-to-date with the latest Volume & Revenue figures.",
      navigationPath: "analytics/crm", // TODO
      buttonTitle: OPEN_DASHBOARD,
      media: {
        type: "image",
        url: background3,
      },
    },
  },
  {
    id: "7b17bedb-dfad-4910-9e70-9d249f82da93",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_ANALYTICS_CUSTOMER_PROFIT",
    question: "APPLICATION",
    data: {
      headerTitle: "Analytics",
      title: "Customer Profitability",
      text: "Stay up-to-date with the latest customer profitability figures.",
      navigationPath: null,
      buttonTitle: OPEN_DASHBOARD,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "72cba060-8380-4c3d-8097-01a12964b428",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_ANALYTICS_CUSTOMER_COMPLIANCE",
    question: "APPLICATION",
    data: {
      headerTitle: "Analytics",
      title: "Customer Compliance",
      text: "Stay up-to-date with the latest customer compliance figures.",
      navigationPath: null,
      buttonTitle: OPEN_DASHBOARD,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "7caaec1b-5620-4437-a6cc-bdb4489e5a5e",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_ANALYTICS_CUSTOMER_FEEDBACK",
    question: "APPLICATION",
    data: {
      headerTitle: "Analytics",
      title: "Customer Feedback",
      text: "Stay up-to-date with the latest customer feedback figures.",
      navigationPath: null,
      buttonTitle: OPEN_DASHBOARD,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },

  {
    id: "b8430351-a4e7-4bad-9e51-d7a0c86f93ee",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_ANALYTICS_CUSTOMER_DELIVER_SERV",
    question: "APPLICATION",
    data: {
      headerTitle: "Analytics",
      title: "Customer Delivery Service",
      text: "Stay up-to-date with the latest customer delivery service figures.",
      navigationPath: null,
      buttonTitle: OPEN_DASHBOARD,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "036495c5-dfae-484a-99f8-dfab26305882",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_ANALYTICS_CUSTOMER_COLLECT_SERV",
    question: "APPLICATION",
    data: {
      headerTitle: "Analytics",
      title: "Customer Collection Service",
      text: "Stay up-to-date with the latest customer collection service figures.",
      navigationPath: null,
      buttonTitle: OPEN_DASHBOARD,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "2dd70d9a-a254-4b44-ab4d-895838cbe20b",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_ANALYTICS_CUSTOMER_GREEN_STAT",
    question: "APPLICATION",
    data: {
      headerTitle: "Analytics",
      title: "Customer Green Statistics",
      text: "Stay up-to-date with the latest customer green statistics.",
      navigationPath: null,
      buttonTitle: OPEN_DASHBOARD,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "c7b1a1ea-a2c7-4b83-9198-e721bc09a999",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_ANALYTICS_CRM_FOCUS_REPORTS",
    question: "APPLICATION",
    data: {
      headerTitle: "Analytics",
      title: "CRM Focus Reports",
      text: "Stay up-to-date with the latest CRM focus reports.",
      navigationPath: null,
      buttonTitle: OPEN_DASHBOARD,
      actionDisabled: true,
      media: {
        type: "image",
        url: background4,
      },
    },
  },
  {
    id: "8706642d-e3d4-4b6e-b259-667471b1e0c3",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_ANALYTICS_ALL_FIELDS",
    question: "APPLICATION",
    data: {
      headerTitle: "Analytics",
      title: "List all Fields",
      text: "View the full list of fields that you can chat about here.",
      navigationPath:
        "https://drive.google.com/file/d/1aTXwE9el5OvETNCalvbbbIuF194iV1v-/view",
      externalLink: true,
      media: {
        type: "image",
        url: background,
      },
    },
  },
  {
    id: "3b901495-4c59-41e4-8908-cabca3debe45",
    topicId: EMPTY_TOPIC_ID,
    type: "APP_ANALYTICS_HELPER",
    question: "APPLICATION",
    data: {
      headerTitle: "Analytics",
      title: "Analytics Helper Guide",
      text: "Take a look at some example questions you can ask to help make the most of your personal assistant.",
      navigationPath:
        "https://drive.google.com/file/d/1jWIjAwVkRu9Atc0Ox73pEUXImgH4Kvrm/view",
      externalLink: true,
      media: {
        type: "image",
        url: background2,
      },
    },
  },
];

export const ALL_APPLICATIONS_WIDGETS = keyBy(
  [...NEWS_CARDS, ...DOCS_CARDS, ...ANALYTICS_CARDS],
  "type"
);

export const APPLICATION_CONTENT_MAP = {
  [`/${SUB_NAV_ROUTES.NEWS}`]: NEWS_CARDS,
  [`/${SUB_NAV_ROUTES.DOCS}`]: DOCS_CARDS,
  [`/${SUB_NAV_ROUTES.ANALYTICS}`]: ANALYTICS_CARDS,
};
