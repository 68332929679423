import styled from "@emotion/styled";

const Title = styled("div")(({ theme }) => ({
  fontFamily: theme.fonts.plutoRegular,
  fontSize: 16,
  color: theme.colors.brandBlack,
  fontWeight: 400,
  lineHeight: "160%",
  letterSpacing: "0.15px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "2",
}));

const CardTitle = ({ children, className }) => (
  <Title className={className}>{children}</Title>
);

export default CardTitle;
