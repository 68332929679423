import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { makeStyles } from "@mui/styles";
import { memo } from "react";

const useStyles = makeStyles({
  icon: {
    width: "26px",
    height: "26px",
    margin: "1px 0 0 1px",
  },
});

export const ErrorIcon = memo(() => {
  const styles = useStyles();

  return (
    <SvgIcon viewBox="0 0 30 30" className={styles.icon}>
      <path
        d="M12.884 2.532c-.346-.654-1.422-.654-1.768 0l-9 17A.999.999 0 0 0 3 21h18a.998.998 0 0 0 .883-1.467L12.884 2.532zM13 18h-2v-2h2v2zm-2-4V9h2l.001 5H11z"
        fill="white"
        fillOpacity="0.87"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
});
