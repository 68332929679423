export const DEPOT = "DEPOT";
export const OUTER = "OUTER";
export const SECTOR = "SECTOR";
export const POSTCODE = "POSTCODE";

export const LAYERS = {
  DEPOT: {
    BOUNDARIES: {
      NAME: "depot-boundary-district",
      SOURCE_LAYER: "boundaries_postal_2",
    },
    MULTI: {
      NAME: "multi-district",
      SOURCE_LAYER: "boundaries_postal_2",
    },
  },
  OUTER: {
    BOUNDARIES: {
      NAME: "depot-boundary-district",
      SOURCE_LAYER: "boundaries_postal_2",
    },
    MULTI: {
      NAME: "multi-district",
      SOURCE_LAYER: "boundaries_postal_2",
    },
  },
  SECTOR: {
    BOUNDARIES: {
      NAME: "depot-boundary-sector",
      SOURCE_LAYER: "boundaries_postal_3",
    },
    MULTI: {
      NAME: "multi-sector",
      SOURCE_LAYER: "boundaries_postal_2",
    },
  },
};
