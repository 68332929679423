import React from "react";
import { Layer } from "react-mapbox-gl";
import { groupBy } from "lodash";
import { BOUNDARIES_ID_BY_LEVEL } from "../../../../../constants/sources";
import { LAYERS, DEPOT, OUTER, SECTOR } from "../../../../../constants/layers";
import { palette } from "../../../../../styles/palette";
import { HEATMAP_VALUES } from "../../../../../constants/map";

export const DepotBoundary = ({
  map,
  parcelMapData,
  mapLevel,
  districtBoundaries,
  sectorBoundaries,
  depotColourMap,
  pageToShowIndex,
}) => {
  const districtBoundariesByDepot = Object.entries(
    groupBy(districtBoundaries, "depotCode")
  );
  const sectorBoundariesByDepot = Object.entries(
    groupBy(sectorBoundaries, "depotCode")
  );

  const getLayerProps = (level, depotCode, depotBoundaries) => ({
    filter: [
      "in",
      ["id"],
      ["literal", depotBoundaries.map((boundary) => boundary.id)],
    ],
    id: depotCode,
    sourceId: BOUNDARIES_ID_BY_LEVEL[level],
    sourceLayer: LAYERS[level].BOUNDARIES.SOURCE_LAYER,
    type: "fill",
    paint: layerPaint(depotBoundaries, depotCode),
    layout: getLayout(depotBoundaries),
  });

  const getLayout = (depotBoundaries) => {
    // fill-sort-key is the rendering order of the feature, the lower numbers are placed on the map first
    const outlineColourExpressionArray = ["match", ["id"]];
    depotBoundaries.forEach(({ id, depotCode, originalDepot }) => {
      outlineColourExpressionArray.push(id);
      if (depotCode === originalDepot) {
        outlineColourExpressionArray.push(1);
      } else {
        outlineColourExpressionArray.push(0);
      }
    });
    outlineColourExpressionArray.push(0);

    return {
      "fill-sort-key": outlineColourExpressionArray,
    };
  };

  const layerPaint = (depotBoundaries, depotCode) => {
    let heatmap = [];
    let parcelIndex;
    let depotColourIndex;
    let fillColourExpressionArray;

    let heatmapValues;
    pageToShowIndex === 0
      ? (heatmapValues = HEATMAP_VALUES.SIMULATION)
      : (heatmapValues = HEATMAP_VALUES.ALLOCATION);

    if (pageToShowIndex === 0) {
      heatmap = palette.heatmap;
    } else if (pageToShowIndex === 1) {
      parcelIndex = parcelMapData.findIndex(
        (parcel) => parcel.depotCode === depotCode
      );
      depotColourIndex = depotColourMap.findIndex(
        (depotMap) => depotMap.depot === depotCode
      );
      heatmap = depotColourMap[depotColourIndex].mapColourVariations;

      // Any depot that has changed from the original allocation with have a brighter shade - this is only for the allocation page
      fillColourExpressionArray = ["match", ["id"]];
      depotBoundaries.forEach(({ id, depotCode, originalDepot }) => {
        fillColourExpressionArray.push(id);
        if (depotCode === originalDepot) {
          fillColourExpressionArray.push(
            depotColourMap[depotColourIndex].mapColour
          );
        } else {
          fillColourExpressionArray.push(
            depotColourMap[depotColourIndex].altMapColour
          );
        }
      });
      fillColourExpressionArray.push("#FFF");
    }

    if (pageToShowIndex === 1 && depotCode && parcelIndex === -1) {
      // Postcode Allocation only
      return {
        "fill-color":
          mapLevel === DEPOT
            ? depotColourMap[depotColourIndex].mapColour
            : ["to-color", fillColourExpressionArray],
        "fill-opacity": 0.9,
        "fill-outline-color": mapLevel === DEPOT ? "transparent" : "#000",
      };
    } else if (parcelMapData.length < 1) {
      return {
        "fill-color": "#CCCCCC",
        "fill-opacity": 0.6,
        "fill-outline-color": "#000",
      };
    } else {
      let totalStops;
      let fillColourList = [];

      depotBoundaries.forEach((boundary) => {
        const id = boundary.id;

        if (pageToShowIndex === 1) {
          // Postcode AND Depot needed to differentiate between DPD and Local depots
          parcelIndex = parcelMapData.findIndex((parcel) => {
            return (
              parcel.value === boundary.postcode &&
              parcel.depotCode === depotCode
            );
          });
        } else {
          parcelIndex = parcelMapData.findIndex((parcel) => {
            return parcel.value === boundary.postcode;
          });
        }

        parcelMapData[parcelIndex]?.simStops
          ? (totalStops = parcelMapData[parcelIndex]?.simStops)
          : (totalStops = parcelMapData[parcelIndex]?.stops);

        fillColourList.push({
          ...getFillColour(heatmap, totalStops, heatmapValues, id),
        });
      });

      const fillColourExpressionArray = ["match", ["id"]];

      fillColourList.forEach(({ id, heatmap }) => {
        fillColourExpressionArray.push(id);
        fillColourExpressionArray.push(heatmap);
      });
      fillColourExpressionArray.push("#FFF"); // Fallback

      let paint;
      mapLevel === DEPOT && pageToShowIndex === 1
        ? (paint = {
            "fill-color": depotColourMap[depotColourIndex].mapColour,
            "fill-opacity": 0.9,
            "fill-outline-color": "transparent",
          })
        : (paint = {
            "fill-color": ["to-color", fillColourExpressionArray],
            "fill-opacity": 0.9,
            "fill-outline-color": "#000",
          });

      return paint;
    }
  };

  const getFillColour = (colours, totalStops, heatmapValues, id) => {
    const maxIndex = heatmapValues.length - 1;

    if (totalStops === heatmapValues[0] || totalStops === undefined) {
      return { id, heatmap: "#FFF" };
    }

    for (let i = 1; i <= maxIndex; i++) {
      if (totalStops <= heatmapValues[i]) {
        return { id, heatmap: colours[i - 1] };
      }
    }

    return { id, heatmap: colours[maxIndex] };
  };

  return (
    map && (
      <>
        {(mapLevel === OUTER || mapLevel === DEPOT) && (
          <>
            {districtBoundariesByDepot.map((depot) => (
              // depot[0] = DEPOT CODE, depot[1] = BOUNDARIES
              <Layer
                key={depot[0]}
                {...getLayerProps(OUTER, depot[0], depot[1])} // Depots do not have features in the mapbox API so the outer level must be used
              />
            ))}
          </>
        )}
        {mapLevel === SECTOR && (
          <>
            {sectorBoundariesByDepot.map((depot) => (
              // depot[0] = DEPOT CODE, depot[1] = BOUNDARIES
              <Layer
                key={depot[0]}
                {...getLayerProps(SECTOR, depot[0], depot[1])}
              />
            ))}
          </>
        )}
      </>
    )
  );
};
