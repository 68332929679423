import { createSlice } from "@reduxjs/toolkit";
import { OUTER } from "../../constants/layers";
import { DEFAULT_MAPBOUNDS } from "../../constants/map";

const initialState = {
  mapLevel: OUTER,
  mapBounds: DEFAULT_MAPBOUNDS,
  isMapAdjusting: true,
  showMapParcelDataMarkers: true,
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setMapLevel: (state, action) => {
      state.mapLevel = action.payload;
    },
    setMapBounds: (state, action) => {
      state.mapBounds = action.payload;
    },
    setIsMapAdjusting: (state, action) => {
      state.isMapAdjusting = action.payload;
    },
    setShowMapParcelDataMarkers: (state, action) => {
      state.showMapParcelDataMarkers = action.payload;
    },
  },
});

const { reducer, actions } = mapSlice;
const {
  setMapLevel,
  setMapBounds,
  setIsMapAdjusting,
  setShowMapParcelDataMarkers,
} = actions;
export {
  initialState,
  reducer,
  setMapLevel,
  setMapBounds,
  setIsMapAdjusting,
  setShowMapParcelDataMarkers,
};
