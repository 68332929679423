export const ZOOM_LEVELS = {
  OUTER: 9.5,
  SECTOR: 11.5,
};

export const DEFAULT_MAPBOUNDS = [
  [-7.57216793459, 47.959999905],
  [1.68153079591, 58.6350001085],
];

export const DEFAULT_MAPZOOM = [6];

export const HEATMAP_VALUES = {
  SIMULATION: [0, 50, 100, 150, 200, 250],
  ALLOCATION: [0, 50, 100, 200, 300, 400, 500, 750, 1000, 1500],
};
