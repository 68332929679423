import { api } from "./api";

export const getWidget = (id, signal) => api.get(`/widgets/${id}`, { signal });

export const getWidgets = () => api.get(`/widgets`);

export const createWidget = (body) => api.post(`/widgets`, body);

export const setUserWidgets = (body) => api.put(`/widgets`, body);

export const deleteUserWidget = (id) => api.delete(`/widgets/${id}`);

export const getCategoryWidgets = (category, signal) =>
  api.get(`/widgets/category/${category}`, { signal });
