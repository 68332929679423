import React, { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

export const LocationIcon = memo((props) => {
  return (
    <SvgIcon viewBox="0 0 13 16" {...props}>
      <path
        d="M6.27255 0C2.82332 0 0.0012207 2.71455 0.0012207 6.05554C0.0012207 7.37801 0.45415 8.63088 1.25549 9.67494L5.99382 15.8697C6.09835 16.0089 6.34223 16.0437 6.48159 15.9393C6.51643 15.9045 6.51643 15.9045 6.55127 15.8697L11.2896 9.67494C12.0909 8.63088 12.5439 7.37801 12.5439 6.05554C12.5439 2.71455 9.72178 0 6.27255 0ZM5.78478 8.42207C4.46083 8.14366 3.58981 6.89079 3.86854 5.56831C4.14727 4.24584 5.40153 3.37579 6.72548 3.6542C7.70102 3.86302 8.46751 4.59386 8.64172 5.56831C8.8856 6.89079 8.01458 8.17846 6.72548 8.42207C6.41191 8.49168 6.09835 8.49168 5.78478 8.42207Z"
        fillOpacity="0.87"
      />
    </SvgIcon>
  );
});
