import React from "react";
import { setUpSimulationParcelList } from "../../../utils/map";
import { OUTER } from "../../../constants/layers";
import {
  defaultOverlayDataSelector,
  overlayTimeBandSelector,
  pageToShowIndexSelector,
  simulatedVolumeOverlayDataSelector,
} from "../../../reducers/app/selector";
import { useSelector } from "react-redux";
import "./SubHeader.css";

export const SubHeader = () => {
  const dataList = useSelector(defaultOverlayDataSelector);
  const simulatedList = useSelector(simulatedVolumeOverlayDataSelector);
  const overlayTimeBand = useSelector(overlayTimeBandSelector);
  const pageToShowIndex = useSelector(pageToShowIndexSelector);

  const districtParcelTotals = setUpSimulationParcelList(
    OUTER,
    dataList,
    simulatedList,
    overlayTimeBand,
    "total"
  );

  return (
    <div className="subHeader">
      {pageToShowIndex === 0 ? (
        <>
          {dataList.length > 0 ? (
            <div className="subHeaderTotalContainer">
              <h5>Total Stops:</h5>
              <div className="subHeaderTotalStops">
                {districtParcelTotals.total}
              </div>
            </div>
          ) : (
            <div>Depot summary</div>
          )}
          {simulatedList && (
            <div className="subHeaderTotalContainer">
              <h5>Simulated Stops:</h5>
              <div className="subHeaderTotalStops">
                {districtParcelTotals.simTotal}
              </div>
            </div>
          )}
        </>
      ) : (
        <div>UK summary</div>
      )}
    </div>
  );
};
