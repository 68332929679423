import { reducer as vs_app } from "./app";
import { reducer as depot } from "./depot";
// import { reducer as user } from "./user";
import { reducer as map } from "./map";

const volumeSimStore = {
  vs_app,
  depot,
  // user,
  map,
};

export default volumeSimStore;
