import styled from '@emotion/styled';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const HeaderTitles = ({ children, className }) => (
  <Container className={className}>{children}</Container>
);

export default HeaderTitles;
