import Card from "../widgets/ContentCard";
import LinkItem from "../LinkList/LinkItem";

const ActionButton = ({ link, onClick }) => (
  <Card.CardActions>
    <Card.ActionButton>
      <LinkItem {...link} onClick={onClick} />
    </Card.ActionButton>
  </Card.CardActions>
);

export default ActionButton;
