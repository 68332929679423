import React, { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { AppActions } from "@dpdgroupuk/ai-app";

import { MessagesSelectors, MessagesActions, UserSelectors } from "../../store";
import PageContainer from "../../components/grid/PageContainer.jsx";
import Chat from "../../pages/Home/components/Chat/Chat.jsx";
import InfoCard from "./components/InfoCard";
import OutlinedButton from "./components/Button/OutlinedButton.js";
import useModal from "../hooks/useModal";
import { useChatActions } from "../hooks/useChatActions";
import { trimString } from "../../utils/string.js";
import { OPERATIONS_PRODUCTS } from "./constants/products.js";
import { getFormattedMessages } from "../../pages/models/messages";

const Grid = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(4, 1fr)`,
  // rowGap: theme.spaces.xxLarge,
  columnGap: theme.dimensions.home.columnGap,
}));

const GridItem = styled("div")(({ colEnd = 1, rowEnd = 1 }) => ({
  height: "100%",
  width: "100%",
  margin: "0 auto",
  gridColumnEnd: `span ${colEnd}`,
  gridRowEnd: `span ${rowEnd}`,
}));

const ChatPageContainer = styled(Grid)(({ theme, isChatActive }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  alignItems: "stretch",
  [theme.breakpoints.down("md")]: {
    width: "200vw",
    paddingBottom: `${theme.dimensions.chat.switchButtonsHeight}px`,
    transition: "0.5s ease",
    transform: isChatActive ? "translateX(0)" : "translateX(-50%)",
  },
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  wordBreak: "break-word",
  alignItems: "center",
  // margin: `0 ${theme.spaces.xLarge}px`,
  [theme.breakpoints.down("md")]: {
    margin: `0 ${theme.spaces.xxLarge}px`,
  },
}));

export const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setLoading } = AppActions;

  const [onSendMessage, isTyping, isLoading, activeTopicId] = useChatActions();
  const user = useSelector(UserSelectors.getUser);
  const messages = useSelector(MessagesSelectors.getMessages);

  const preparedMessages = useMemo(
    () => getFormattedMessages(messages),
    [messages]
  );

  const onSubmitFeedback = useCallback(
    async (messageId, feedbackComment) => {
      const { payload } = dispatch();
      MessagesActions.dislikeMessage({
        messageId,
        feedbackComment,
      });

      if (payload) {
        dispatch(MessagesActions.dislikeLocalMessage(messageId));
      }
    },
    [dispatch]
  );

  const { onModalOpen } = useModal({
    onSubmit: onSubmitFeedback,
  });

  const onThumbUpClick = useCallback(
    async (messageId) => {
      const { payload } = await dispatch();
      MessagesActions.likeMessage(messageId);

      if (payload) {
        dispatch(MessagesActions.likeLocalMessage(messageId));
      }
    },
    [dispatch]
  );

  const sendMessageToVertex = async (message, setMessage) => {
    await onSendMessage(trimString(message));
    setMessage("");
    dispatch(setLoading(true));
    try {
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

  return (
    <PageContainer>
      <Grid>
        <GridItem colEnd={2} rowEnd={2}>
          <ChatPageContainer isChatActive={true}>
            <ContentWrapper>
              <Chat
                messages={preparedMessages}
                user={user}
                onThumbDownClick={onModalOpen}
                onThumbUpClick={onThumbUpClick}
                onSendClickChat={sendMessageToVertex}
                isTyping={isTyping}
                isLoading={isLoading}
                activeTopicId={activeTopicId}
              />
            </ContentWrapper>
          </ChatPageContainer>
        </GridItem>
        <GridItem colEnd={2} rowEnd={2}>
          <InfoCard
            data={{
              cardTitle: "Navigate to",
              title: "DPD Operational Products",
              text: OPERATIONS_PRODUCTS.map((product) => (
                <OutlinedButton
                  disabled={product.disabled}
                  onClick={() => navigate(product.link)}
                >
                  {product.title}
                </OutlinedButton>
              )),
            }}
          />
        </GridItem>
      </Grid>
    </PageContainer>
  );
};
