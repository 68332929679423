import { ThemeProvider } from "@mui/material";
import "./App.css";
import { SiteRoutes } from "./router/Routes";
import appTheme from "./theme";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={appTheme}>
        <SiteRoutes />
      </ThemeProvider>
    </div>
  );
}

export default App;
