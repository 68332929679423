import styled from '@emotion/styled';

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.brandWhite,
  borderRadius: theme.dimensions.card.borderRadius, // todo move to gridItem
  boxShadow: theme.dimensions.card.boxShadow, // todo move to gridItem
  padding: `${theme.dimensions.card.paddingVertical}px ${theme.dimensions.card.paddingHorizontal}px`,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const CardContainer = ({ children, className }) => (
  <Container className={className}>{children}</Container>
);

export default CardContainer;
