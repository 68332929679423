import React, { memo } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  icon: {
    width: "18px",
    height: "18px",
    marginTop: "3px",
  },
});

export const ArrowIcon = memo(() => {
  const styles = useStyles();

  return (
    <SvgIcon viewBox="0 0 30 30" className={styles.icon}>
      <path
        d="M24 12l-9-8v6h-15v4h15v6z"
        fill="white"
        fillOpacity="0.87"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
});
