export const SUBMIT = 'Submit';
export const SUBMIT_FEEDBACK = 'Submit Feedback';
export const WELCOME_SUBHEADER = 'How can I help?';
export const UNABLE_TO_$ = toWhat => `Unable to ${toWhat}`;
export const SESSION_EXPIRED = 'Your session has been expired';
// TODO: try to reuse these error messages
export const NO_PERMISSIONS =
  'You do not have permission to access this product';
export const UNABLE_TO_GET_MESSAGES = UNABLE_TO_$('get messages');
export const UNABLE_TO_PROCESS_THE_REQUEST = UNABLE_TO_$('process the request');
export const UNABLE_TO_SEND_THE_MESSAGE = UNABLE_TO_$('send the message');

export const CONFIRM_DELETE_WIDGET_MESSAGE =
  'Do you want to remove this widget?';
export const DECLINE_DELETE_WIDGET = 'No, keep widget';
export const CONFIRM_DELETE_WIDGET = 'Yes, remove widget';
export const LOADING_WIDGET_ERROR =
  'We’re having trouble loading this widget right now.';
export const RETRY = 'Retry';
export const YOU_ASKED_QUESTION = 'You asked the following question';
export const RELOAD_WIDGETS = 'Reload widgets';
export const RELOAD = 'Reload';
export const RELOAD_WIDGETS_MESSAGE = 'Some widgets failed to load';
export const ASK_ME_ANYTHING = 'Ask me anything';
export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const HOW_CAN_I_HELP = 'How can I help?';
export const DPD_CHAT = 'DPDChat';
export const SEND = 'Send';
export const DOCUMENTS = 'Documents';
export const SEARCH_AND_FILTER = 'Search & Filter';
export const SEARCH = 'Search';
export const SORT_BY = 'Sort by';
export const NEWEST_FIRST = 'Newest first';
export const APPLY = 'Apply';
export const APPLICATIONS = 'Applications';
export const DONE = 'Done';
export const RELATED_TOPICS = 'Related Topics';
export const MENU = 'Menu';
export const SHOW_MORE = 'Show more';
export const SIGN_OUT = 'Sign out';
export const TOPIC_HISTORY = 'Topic History';
export const SCHEDULE = 'Schedule';
export const CANCEL = 'Cancel';
export const CONFIRM = 'Confirm';
export const VIEW_DOCUMENT = 'View Document';
export const LISTEN_TO_PODCAST = 'Listen to Podcast';
export const OPEN_DASHBOARD = 'Open Dashboard';
export const OPEN_FOLDER = 'Open Folder';
export const OPEN_LINK = 'Open Link';
